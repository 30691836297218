import React from "react";
import BodyfatCalc from "../components/BodyfatCalc";

const BodyFatCalculator = () => {
  return (
    <div className="max-w-[1000px] m-auto shadow-xl rounded-xl md:pb-10 pt-5 md:pt-10 md:mt-10 md:bg-gray-900 border-2 border-gray-700">

    <div className="page-container max-w-[900px] rounded-lg">
    
          <BodyfatCalc />
      
    </div>
    </div>
  );
};

export default BodyFatCalculator;
