import React, { useEffect, useState } from "react";

const BodyfatCalc = () => {
  const [gender, setGender] = useState("male");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [neck, setNeck] = useState("");
  const [waist, setWaist] = useState("");
  const [hip, setHip] = useState("");
  const [bf, setBf] = useState("");
  const [bfc, setBfc] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);

  const [heightMeasurement, setHeightMeasurement] = useState("feet");
  const [weightMeasurement, setWeightMeasurement] = useState("kg");
  const [neckMeasurement, setNeckMeasurement] = useState("inch");
  const [waistMeasurement, setWaistMeasurement] = useState("inch");
  const [hipMeasurement, setHipMeasurement] = useState("inch");

  const [heightFeet, setHightFeet] = useState("");
  const [heightFeetInch, setHeightFeetInch] = useState("");

  const [weightStone, setWeightStone] = useState("");
  const [weightStonePound, setWeightStonePound] = useState("");

  const [neckFeet, setNeckFeet] = useState("");
  const [neckFeetInch, setNeckFeetInch] = useState("");

  const [waistFeet, setWaistFeet] = useState("");
  const [waistFeetInch, setWaistFeetInch] = useState("");

  const [hipFeet, setHipFeet] = useState("");
  const [hipFeetInch, setHipFeetInch] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (bf !== "") {
    }
    if (bf >= 2.5 && bf < 6) {
      setBfc("Essential fat");
    } else if (bf > 6 && bf < 14) {
      setBfc("Athletes");
    } else if (bf > 14 && bf < 18) {
      setBfc("Fitness");
    } else if (bf > 18 && bf < 25) {
      setBfc("Average");
    } else if (bf >= 25) {
      setBfc("Obese");
    }
  }, [bf]);

  const handleHeightChange = (e) => {
    setHeightMeasurement(e.target.value);
    setHeight("");
    setHightFeet("");
    setHeightFeetInch("");
  };

  const handleWeightChange = (e) => {
    setWeightMeasurement(e.target.value);
    setWeight("");
    setWeightStone("");
    setWeightStonePound("");
  };

  const handleNeckChange = (e) => {
    setNeckMeasurement(e.target.value);
    setNeck("");
    setNeckFeetInch("");
    setNeckFeet("");
  };

  const handleWaistChange = (e) => {
    setWaistMeasurement(e.target.value);
    setWaist("");
    setWaistFeetInch("");
    setWaistFeet("");
  };

  const handleHipChange = (e) => {
    setHipMeasurement(e.target.value);
    setHip("");
    setHipFeetInch("");
    setHipFeet("");
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const reload = () => {
    setHip("");
    setHipFeetInch("");
    setHipFeet("");
    setWaist("");
    setWaistFeetInch("");
    setWaistFeet("");
    setNeck("");
    setNeckFeetInch("");
    setNeckFeet("");
    setHeight("");
    setHightFeet("");
    setHeightFeetInch("");
    setBf("");
    setWeight("");
    setWeightStonePound("");
    setWeightStone("");
    setFormSubmit(false);
  };

  const heightSwitch = () => {
    if (heightMeasurement === "inch") {
      return height * 2.54;
    } else if (heightMeasurement === "cm") {
      return height;
    } else if (heightMeasurement === "feet") {
      const feet = heightFeet * 12;
      const inches = heightFeetInch;
      const feetPlusInches = Number(feet) + Number(inches);
      const result = feetPlusInches * 2.54;
      return Number(result);
    }
  };

  const weightSwitch = () => {
    if (weightMeasurement === "kg") {
      return weight * 2.205;
    } else if (weightMeasurement === "lb") {
      return weight;
    } else if (weightMeasurement === "stone") {
      const stone = weightStone * 14;
      const pounds = weightStonePound;
      const stonePlusPounds = Number(stone) + Number(pounds);
      const result = stonePlusPounds;
      return Number(result);
    }
  };

  const neckSwitch = () => {
    if (neckMeasurement === "inch") {
      return neck * 2.54;
    } else if (neckMeasurement === "cm") {
      return neck;
    } else if (neckMeasurement === "feet") {
      const feet = neckFeet * 12;
      const inches = neckFeetInch;
      const feetPlusInches = Number(feet) + Number(inches);
      const result = feetPlusInches * 2.54;
      return Number(result);
    }
  };

  const hipSwitch = () => {
    if (hipMeasurement === "inch") {
      return hip * 2.54;
    } else if (hipMeasurement === "cm") {
      return Number(hip);
    } else if (hipMeasurement === "feet") {
      const feet = hipFeet * 12;
      const inches = hipFeetInch;
      const feetPlusInches = Number(feet) + Number(inches);
      const result = feetPlusInches * 2.54;
      return Number(result);
    }
  };

  const waistSwitch = () => {
    if (waistMeasurement === "inch") {
      return waist * 2.54;
    } else if (waistMeasurement === "cm") {
      return +waist;
    } else if (waistMeasurement === "feet") {
      const feet = waistFeet * 12;
      const inches = waistFeetInch;
      const feetPlusInches = Number(feet) + Number(inches);
      const result = feetPlusInches * 2.54;

      return Number(result);
    }
  };

  const leanBodyMass = Number((weightSwitch(weight) * bf) / 100).toFixed(1);

  const calcBodyFat = (e) => {
    e.preventDefault();
    if (gender === "male") {
      let bf =
        495 /
          (1.0324 -
            0.19077 * Math.log10(waistSwitch(waist) - neckSwitch(neck)) +
            0.15456 * Math.log10(heightSwitch(height))) -
        450;
      setBf(bf);
      setFormSubmit(true);
    } else if (gender === "female") {
      let bf =
        495 /
          (1.29579 -
            0.35004 *
              Math.log10(
                waistSwitch(waist) + hipSwitch(hip) - neckSwitch(neck)
              ) +
            0.221 * Math.log10(heightSwitch(height))) -
        450;
      setBf(bf);
      setFormSubmit(true);
    }
  };

  return (
    <div className="grid  pb-4 md:my-4 md:mx-4 grid-cols-1 md:grid-cols-1 md:gap-12 gap-8">
     
      <form onSubmit={calcBodyFat}>
        <div className="flex flex-col order-1 md:bg- md:p-4">
          <h1 className="text-3xl mb-2 font-medium border-b-[1px] pb-4">
            Body Fat Calculator{" "}
          </h1>
          <p className="">
            You can use the Body Fat Calculator to estimate your total body fat
            based on the U.S. Navy method.
          </p>
          <hr className="my-2" />

          <div className="flex">
            <h3>Instructions:</h3>
            <span
              className="text-blue-700 font-bold underline ml-2 cursor-pointer"
              onClick={() => setShowDetails(!showDetails)}
            >
              Body Fat Calculator
            </span>
          </div>
          {showDetails && (
            <div className="bg-gray-100 p-4 rounded mt-3">
              <h3 className="font-semibold">Steps</h3>
              <hr className="my-2" />

              <ul className="list-disc  ml-4 mt-2 text-gray-700">
                <li>Select gender </li>
                <li>Enter your height </li>
                <li>Enter your body weight </li>
                <li>Enter your neck measurement </li>
                <li>Enter your waist measurement </li>
                <li>Enter your hip measurement (women only) </li>
              </ul>
              <hr className="my-2" />
              <div className="">
                <h2 className="font-bold pt-2">More Details</h2>
                <hr className="my-2" />

                <div>
                  <h3 className="font-semibold">Neck Measurements</h3>
                  <p>
                    Measure your neck. Start below the larynx (Adam's apple)
                    with the tape measure perpendicular to the long axis of the
                    neck{" "}
                  </p>
                </div>
                <hr className="my-2" />

                <div>
                  <h3 className="font-semibold">Waist Measurements</h3>

                  <p>
                    Use the circumference of your waist at a horizontal level
                    around the navel for men, and at the level with the least
                    width for women.
                  </p>
                </div>
                <hr className="my-2" />

                <div>
                  <h3 className="font-semibold">Hip Measurements</h3>

                  <p>
                    Measure your hips if you are a woman. Place the tape measure
                    around the largest width of your hips. If wearing clothes,
                    pull the tape measure somewhat taught to account for the
                    bulk of your clothing.
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="flex mt-8">
            <div className="pr-4">
              <label className="block tracking-wide text-gray-700 pt-2 font-semibold text-sm">
                Gender
              </label>
            </div>
            <select
              className=" bg-gray-50 cursor-pointer"
              value={gender}
              disabled={formSubmit}
              onChange={handleGenderChange}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <div className="flex mt-6">
            <div className="mr-[21px]">
              <label className="block tracking-wide text-gray-700  pt-2 font-semibold text-sm">
                Height
              </label>
            </div>
            {heightMeasurement === "feet" && (
              <div className="grow">
                <input
                  className="rounded-none rounded-l-lg"
                  value={heightFeet}
                  type="number"
                  required
                  placeholder="feet"
                  disabled={formSubmit}
                  onChange={(e) => setHightFeet(e.target.value)}
                />
              </div>
            )}
            {heightMeasurement === "feet" && (
              <div className="grow">
                <input
                  className="rounded-none"
                  value={heightFeetInch}
                  type="number"
                  required
                  placeholder="inch"
                  disabled={formSubmit}
                  onChange={(e) => setHeightFeetInch(e.target.value)}
                />
              </div>
            )}

            {heightMeasurement === "cm" && (
              <div className="grow">
                <input
                  className="rounded-none rounded-l-lg"
                  value={height}
                  type="number"
                  required
                  placeholder="cm"
                  disabled={formSubmit}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </div>
            )}

            {heightMeasurement === "inch" && (
              <div className="grow">
                <input
                  className="rounded-none rounded-l-lg"
                  value={height}
                  type="number"
                  required
                  placeholder="inch"
                  disabled={formSubmit}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </div>
            )}

            <div>
              <select
                className="rounded-none rounded-r-lg min-w-[95px] bg-gray-100 cursor-pointer"
                value={heightMeasurement}
                disabled={formSubmit}
                onChange={handleHeightChange}
              >
                <option value="cm">cm</option>
                <option value="inch">inch</option>
                <option value="feet">feet</option>
              </select>
            </div>
          </div>

          <div className="flex mt-6">
            <div className="mr-[21px]">
              <label className="block tracking-wide text-gray-700  pt-2 font-semibold text-sm">
                Weight
              </label>
            </div>
            {weightMeasurement === "stone" && (
              <div className="grow">
                <input
                  className="rounded-none rounded-l-lg"
                  value={weightStone}
                  type="number"
                  required
                  placeholder="stone"
                  disabled={formSubmit}
                  onChange={(e) => setWeightStone(e.target.value)}
                />
              </div>
            )}
            {weightMeasurement === "stone" && (
              <div className="grow">
                <input
                  className="rounded-none"
                  value={weightStonePound}
                  type="number"
                  required
                  placeholder="pound"
                  disabled={formSubmit}
                  onChange={(e) => setWeightStonePound(e.target.value)}
                />
              </div>
            )}

            {weightMeasurement === "kg" && (
              <div className="grow">
                <input
                  className="rounded-none rounded-l-lg"
                  value={weight}
                  type="number"
                  required
                  placeholder="kg"
                  disabled={formSubmit}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </div>
            )}

            {weightMeasurement === "lb" && (
              <div className="grow">
                <input
                  className="rounded-none rounded-l-lg"
                  value={weight}
                  type="number"
                  required
                  placeholder="pound"
                  disabled={formSubmit}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </div>
            )}

            <div>
              <select
                className="rounded-none rounded-r-lg min-w-[95px] bg-gray-100 cursor-pointer"
                value={weightMeasurement}
                disabled={formSubmit}
                onChange={handleWeightChange}
              >
                <option value="kg">kg</option>
                <option value="lb">lb</option>
                <option value="stone">stone</option>
              </select>
            </div>
          </div>

          <div className="flex mt-6">
            <div className="mr-8">
              <label className="block tracking-wide text-gray-700 pt-2 font-semibold text-sm">
                Neck
              </label>
            </div>
            {neckMeasurement === "feet" && (
              <div className="grow">
                <input
                  className="rounded-none rounded-l-lg"
                  value={neckFeet}
                  type="number"
                  required
                  placeholder="feet"
                  disabled={formSubmit}
                  onChange={(e) => setNeckFeet(e.target.value)}
                />
              </div>
            )}
            {neckMeasurement === "feet" && (
              <div className="grow">
                <input
                  className="rounded-none"
                  value={neckFeetInch}
                  type="number"
                  required
                  placeholder="inch"
                  disabled={formSubmit}
                  onChange={(e) => setNeckFeetInch(e.target.value)}
                />
              </div>
            )}

            {neckMeasurement === "cm" && (
              <div className="grow">
                <input
                  className="rounded-none rounded-l-lg"
                  value={neck}
                  type="number"
                  required
                  placeholder="cm"
                  disabled={formSubmit}
                  onChange={(e) => setNeck(e.target.value)}
                />
              </div>
            )}

            {neckMeasurement === "inch" && (
              <div className="grow">
                <input
                  className="rounded-none rounded-l-lg"
                  value={neck}
                  type="number"
                  required
                  placeholder="inch"
                  disabled={formSubmit}
                  onChange={(e) => setNeck(e.target.value)}
                />
              </div>
            )}

            <div>
              <select
                className="rounded-none rounded-r-lg min-w-[95px] bg-gray-100 cursor-pointer"
                value={neckMeasurement}
                disabled={formSubmit}
                onChange={handleNeckChange}
              >
                <option value="cm">cm</option>
                <option value="inch">inch</option>
                <option value="feet">feet</option>
              </select>
            </div>
          </div>

          <div className="flex mt-6">
            <div className="mr-7">
              <label className="block tracking-wide text-gray-700  pt-2 font-semibold text-sm">
                Waist
              </label>
            </div>
            {waistMeasurement === "feet" && (
              <div className="grow">
                <input
                  className="rounded-none rounded-l-lg"
                  value={waistFeet}
                  type="number"
                  required
                  placeholder="feet"
                  disabled={formSubmit}
                  onChange={(e) => setWaistFeet(e.target.value)}
                />
              </div>
            )}
            {waistMeasurement === "feet" && (
              <div className="grow">
                <input
                  className="rounded-none"
                  value={waistFeetInch}
                  type="number"
                  required
                  placeholder="inch"
                  disabled={formSubmit}
                  onChange={(e) => setWaistFeetInch(e.target.value)}
                />
              </div>
            )}

            {waistMeasurement === "cm" && (
              <div className="grow">
                <input
                  className="rounded-none rounded-l-lg"
                  value={waist}
                  type="number"
                  required
                  placeholder="cm"
                  disabled={formSubmit}
                  onChange={(e) => setWaist(e.target.value)}
                />
              </div>
            )}

            {waistMeasurement === "inch" && (
              <div className="grow">
                <input
                  className="rounded-none rounded-l-lg"
                  value={waist}
                  type="number"
                  required
                  placeholder="inch"
                  disabled={formSubmit}
                  onChange={(e) => setWaist(e.target.value)}
                />
              </div>
            )}

            <div>
              <select
                className="rounded-none rounded-r-lg min-w-[95px] bg-gray-100 cursor-pointer"
                value={waistMeasurement}
                disabled={formSubmit}
                onChange={handleWaistChange}
              >
                <option value="cm">cm</option>
                <option value="inch">inch</option>
                <option value="feet">feet</option>
              </select>
            </div>
          </div>

          {gender === "female" && (
            <div className="flex mt-6">
              <div className="mr-[39px]">
                <label className="block tracking-wide text-gray-700  pt-2">
                  Hip
                </label>
              </div>
              {hipMeasurement === "feet" && (
                <div className="grow">
                  <input
                    className="rounded-none rounded-l-lg"
                    value={hipFeet}
                    type="number"
                    required
                    placeholder="feet"
                    disabled={formSubmit}
                    onChange={(e) => setHipFeet(e.target.value)}
                  />
                </div>
              )}
              {hipMeasurement === "feet" && (
                <div className="grow">
                  <input
                    className="rounded-none"
                    value={hipFeetInch}
                    type="number"
                    required
                    placeholder="inch"
                    disabled={formSubmit}
                    onChange={(e) => setHipFeetInch(e.target.value)}
                  />
                </div>
              )}

              {hipMeasurement === "cm" && (
                <div className="grow">
                  <input
                    className="rounded-none rounded-l-lg"
                    value={hip}
                    type="number"
                    required
                    placeholder="cm"
                    disabled={formSubmit}
                    onChange={(e) => setHip(e.target.value)}
                  />
                </div>
              )}

              {hipMeasurement === "inch" && (
                <div className="grow">
                  <input
                    className="rounded-none rounded-l-lg"
                    value={hip}
                    type="number"
                    required
                    placeholder="inch"
                    disabled={formSubmit}
                    onChange={(e) => setHip(e.target.value)}
                  />
                </div>
              )}

              <div>
                <select
                  className="rounded-none rounded-r-lg min-w-[95px] bg-gray-100 cursor-pointer"
                  value={hipMeasurement}
                  disabled={formSubmit}
                  onChange={handleHipChange}
                >
                  <option value="cm">cm</option>
                  <option value="inch">inch</option>
                  <option value="feet">feet</option>
                </select>
              </div>
            </div>
          )}

          <div className="flex flex-col md:flex-row">
            {bf === "" && (
              <button
                className="flex justify-center ml-[67px]  mb-0 lg:mb-4 shadow bg-blue-700 hover:bg-blue-600  focus:shadow-outline focus:outline-none text-white  py-3 px-6  rounded text-[1.2rem] mt-6 border-b-4 border-blue-900"
                type="submit"
              >
                Calculate Body Fat
              </button>
            )}
            {bf > 0 && (
              <button
                className="flex justify-center ml-[67px] md:w-[300px] lg:mb-4 shadow bg-red-700 hover:bg-red-500 hover:bg-[0.8] focus:shadow-outline focus:outline-none text-white  py-3 px-6 roundedtext-xl mt-8"
                onClick={reload}
              >
                Reset Form
              </button>
            )}

            {(isNaN(bf) || bf < 0) && (
              <>
                <h3 className="bg-red-200 round-xl p-4 mt-5 text-center border-2">
                  Incorrect Measurements
                </h3>
                <button
                  className="flex justify-center md:ml-[67px] md:w-[300px] lg:mb-4 shadow bg-red-700 hover:bg-red-500 hover:bg-[0.8] focus:shadow-outline focus:outline-none text-white  py-3 px-6 rounded text-xl mt-8"
                  onClick={reload}
                >
                  Reset Form
                </button>
              </>
            )}
          </div>
        </div>
      </form>
      {bf > 0 && (
        <ul>
          <li className="text-md bg-gray-100 mt-1 p-2 border-[1px]">
            Body Fat {Number(bf).toFixed(1)}%
          </li>
          <li className="text-md bg-gray-100 mt-1 p-2 border-[1px]">
            Weight {weightSwitch(weight)}lb
          </li>
          <li className="text-md bg-gray-100 mt-1 p-2 border-[1px]">
            Body Fat Category {bfc}{" "}
          </li>
          <li className="text-md bg-gray-100 mt-1 p-2 border-[1px]>Body Fat Mass:">
            {" "}
            Body Fat: {Number(leanBodyMass).toFixed(1)}lb
          </li>
          <li className="text-md bg-gray-100 mt-1 p-2 border-[1px]">
            Lean Body: {Number(weightSwitch(weight) - leanBodyMass).toFixed(1)}
            lb
          </li>
        </ul>
      )}
    </div>
  );
};

export default BodyfatCalc;
