import Select from "react-select";
import KiloTable from "./KiloTable";
import PoundTable from "./PoundsTable";
import { exercisesMale } from "../data/exercisesMale";
import { exercisesFemale } from "../data/exercisesFemale";
import { exerciseNamesSearch } from "../data/exerciseNamesSearch";
import { ImYoutube2 } from "react-icons/im";

import { AiOutlineMan, AiOutlineWoman } from "react-icons/ai";

import { BsInfoLg } from "react-icons/bs";
import { useEffect, useState } from "react";
import classNames from "classnames";
import BodyRatioTable from "./BodyRatioTable";
import StandardsExample from "./StandardsExample";
const Standards = () => {
  const [ex, setEx] = useState("bench press");
  const [killogram, setKilogram] = useState(true);
  const [data, setData] = useState(exercisesMale);

  let exercise = data.find((exercise) => exercise.name === ex);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const kiloStyles = classNames(
    "flex border-[1px] border-gray-400 p-2 justify-center content-center cursor-pointer rounded",

    {
      "bg-blue-700 text-white": killogram,
      " text-black": !killogram,
    }
  );

  const poundStyles = classNames(
    "flex border-[1px] border-gray-400 p-2 justify-center content-center cursor-pointer rounded",
    {
      " text-black": killogram,
      "bg-blue-700 text-white": !killogram,
    }
  );

  const maleStyles = classNames(
    "flex border-[1px] border-gray-400 p-2 justify-center content-center cursor-pointer rounded",
    {
      "bg-blue-700 text-white": data === exercisesMale,
      " text-black": data !== exercisesMale,
    }
  );

  const femaleStyles = classNames(
    "flex border-[1px] border-gray-400 p-2 justify-center content-center cursor-pointer rounded",
    {
      "bg-blue-700 text-white": data === exercisesFemale,
      " text-black": data !== exercisesFemale,
    }
  );

  const onChangeInput = (value) => {
    setEx(value.value);
  };

  const Ytube = `https://www.youtube.com/results?search_query=weightlifting+${ex}`;

  return (
    <div id="anchor">
      <div className="hidden lg:block max-w-[1000px] m-auto shadow-xl rounded-xl lg:pb-10 lg:pt-10 md:pt-10 md:mt-10 md:bg-gray-900 border-2 lg:border-gray-700">
        <div className="hidden lg:block page-container max-w-[900px] rounded-lg">
          <Select
            options={exerciseNamesSearch}
            onChange={onChangeInput}
            placeholder="Select Exercise ..."
          />
        </div>
      </div>

      <div className="block max-w-[1000px] m-auto shadow-xl rounded-xl md:pb-10 pt-5 lg:pt-10 md:mt-10 lg:bg-gray-900 lg:border-2 lg:border-gray-700">
        <div className="page-container max-w-[900px] rounded-lg">
          <Select
            options={exerciseNamesSearch}
            onChange={onChangeInput}
            placeholder="Select Exercise ..."
            className="m-4 block lg:hidden"
          />

          <h1 className="text-[1.7rem] md:text-[2.5rem] font-bold capitalize px-5">
            {exercise.name}
          </h1>

          {killogram ? (
            <p className="px-5 text-xl flex align center justify-between content-center">
              Measured in (kg) {data === exercisesMale ? "Male" : "Female"}
              <a href={Ytube} rel="noreferrer" target="_blank">
                <ImYoutube2 className="text-[40px] mt-[-6px] text-[#212121]" />
              </a>
            </p>
          ) : (
            <p className="px-5 text-xl flex align center justify-between content-center">
              Measured in (lb) {data === exercisesMale ? "Male" : "Female"}
              <a href={Ytube} rel="noreferrer" target="_blank">
                <ImYoutube2 className="text-[40px] mt-[-6px] text-[#212121]" />
              </a>
            </p>
          )}

          <div className="grid  py-2 m-2 mx-5 grid-cols-1 md:grid-cols-2 md:gap-12">
            <div>
              <div className="w-full h-[50px]  text-white  grid grid-cols-2 content-center  text-center text-md font-semibold gap-1 mb-6 mt-[-8px]">
                <div
                  onClick={() => setData(exercisesMale)}
                  className={maleStyles}
                >
                  <AiOutlineMan className="text-[16px] mt-1 rotate-[315deg] mr-2" />
                  Male
                </div>

                <div
                  onClick={() => setData(exercisesFemale)}
                  className={femaleStyles}
                >
                  <AiOutlineWoman className="text-[16px] mt-1 ] mr-2" /> Female{" "}
                </div>
              </div>

              <BodyRatioTable exercise={exercise} />
            </div>

            <StandardsExample exercise={exercise} killogram={killogram} />
          </div>

          <p className="flex text-xs text-left m-7 p-2   font-bold bg-gray-50 border-[1px] rounded text-gray-600">
            <BsInfoLg className="mr-1 text-md" />
            If you are using a barbell, you should include the weight of the
            barbell bar, which is usually 20kg/44lb, and if you are using
            dumbbells, you should only count one dumbbell's weight.
          </p>

          <div className="w-full   text-white grid-cols-2  grid  content-center  text-center text-md font-semibold gap-1 px-6">
            <div onClick={() => setKilogram(true)} className={kiloStyles}>
              Kg
            </div>

            <div onClick={() => setKilogram(false)} className={poundStyles}>
              lb{" "}
            </div>
          </div>

          {killogram && <KiloTable exercise={exercise} />}
          {!killogram && <PoundTable exercise={exercise} />}
        </div>
      </div>
    </div>
  );
};

export default Standards;
