import React from "react";
import Standards from "../components/Standards";

const StandardsData = ({ onChangeInput, exerciseNamesSearch }) => {
  return (
    <>
      <Standards />
    </>
  );
};

export default StandardsData;
