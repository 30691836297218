import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div>
      <div className="grid  place-items-center grid-cols-1  md:py-0 py-0 relative bg-black ">
        <div className="md:text-[250px] text-[125px] text-center font-bold 
          text-white">404</div>
        <div className="md:text-[190px] text-[88px] text-center  absolute pb-[55px] md:pb-[1px] ">💯</div>
        <div className="text-xl text-center mt-5 font-bold text-white">
          Sorry, This page doesn't exist
        </div>
        <Link
          className="pl-1 text-xl underline text-gray-300 text-center mt-5"
          to="/standardsdata"
        >
          <button className="btn bflex justify-center w-full lg:mb-4 shadow bg-red-700 hover:bg-red-600  focus:shadow-outline focus:outline-none text-white  py-3 px-6  rounded text-[1.2rem] mt-8 border-b-4 border-red-900 mb-5">
            Orkuta Home Page
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
