import React from 'react'
import NotFound from '../components/NotFound'

const ErrorPage = () => {
  return (
    <div className=" h-[105%] z-100 w-full  absolute top-[-20px] left-0 grid justify-center content-center 
     bg-black"> 
    
   <NotFound />
    </div>
  )
}

export default ErrorPage