import Navbar from "./Navbar";
import { Link } from "react-router-dom";

const TopNavbar = ({setCalculators, calculators, }) => {

  return (
    <div className="max-w-[100%]">
      <div className="container flex items-center mx-auto  max-w-[1000px] text-white font-bold text-2xl mt-2 p-2">
        <div className="grow text-2xl">
          <Link className="pl-1" onClick={() => setCalculators(false)} to="/standardsdata"> 
          💯 O<span className="text-gray-300">rkuta</span>
          </Link>   
        </div>
        <div> <Link className="pr-1" onClick={() => setCalculators(false)} to="/timeCounter"> 
        
        </Link>   </div>
      </div>
      <Navbar calculators={calculators} setCalculators={setCalculators} />
    </div>
  );
};

export default TopNavbar;
