import React from "react";
import {  GiStrong } from "react-icons/gi";
const StandardsExample = ({ exercise, killogram }) => {
  return (
    <div className=" bg-[#FAFAFA] flex flex-col justify-center mt-[65px] md:mt-0 pb-2  text-slate-600 border-[1px] border-gray-300  shadow-md  rounded">
      <GiStrong className="text-[75px] text-slate-900 relative bottom-8 md:bottom-10 m-auto bg-white border-2   rounded-full p-2" />

      <div className=" text-center font-bold text-xl p-0 bottom-4 text-slate-700 capitalize relative">
        Exercise <span className="text-sm">({exercise.name})</span>{" "}
      </div>

      {killogram && (
        <div className="relative">
          <ul className="text-center">
            <li className="p-1">
              {" "}
              <span className="font-bold text-sm">Beginner:</span>{" "}
              {Math.round(80 * exercise.beginner)}kg
            </li>
            <li className="p-1">
              <span className="font-bold text-sm">Novice:</span>{" "}
              {Math.round(80 * exercise.novice)}kg
            </li>
            <li className="p-1">
              <span className="font-bold text-sm"> Intermediate:</span>{" "}
              {Math.round(80 * exercise.intermediate)}kg
            </li>
            <li className="p-1">
              {" "}
              <span className="font-bold text-sm">Advanced:</span>{" "}
              {Math.round(80 * exercise.advance)}kg
            </li>
            <li className="p-1">
              <span className="font-bold text-sm">Elite:</span>{" "}
              {Math.round(80 * exercise.elite)}kg
            </li>
          </ul>
        </div>
      )}
      {!killogram && (
        <>
        <ul className="text-center">
          <li className="p-1">
            {" "}
            <span className="font-bold text-sm">Beginner:</span>{" "}
            {Math.round(180 * exercise.beginner)}lb
          </li>
          <li className="p-1">
            <span className="font-bold text-sm">Novice:</span>{" "}
            {Math.round(180 * exercise.novice)}lb
          </li>
          <li className="p-1">
            <span className="font-bold text-sm"> Intermediate:</span>{" "}
            {Math.round(180 * exercise.intermediate)}lb
          </li>
          <li className="p-1">
            {" "}
            <span className="font-bold text-sm">Advanced:</span>{" "}
            {Math.round(180 * exercise.advance)}lb
          </li>
          <li className="p-1">
            <span className="font-bold text-sm">Elite:</span>{" "}
            {Math.round(180 * exercise.elite)}lb
          </li>
        </ul>
        </> )}

      {killogram ? (
        <p className="text-xs text-center p-2 pb-2 text-black italic">
          Example of 80kg person
        </p>
      ) : (
        <p className="text-xs text-center p-2 pb-2 text-black italic">
          Example of 180lb person
        </p>
      )}
    </div>
  );
};

export default StandardsExample;
