import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-scroll";

const PushUpsCalc = () => {
  const [age, setAge] = useState("19");
  const [gender, setGender] = useState("male");
  const [value, setValue] = useState(0);
  const [result, setResult] = useState("");

  useEffect(() => {
    const ele = document.querySelector(".buble");
    if (ele) {
      ele.style.left = `${Number(value / 4)}px`;
    }
  });

  const handleGenderChange = (e) => {
    setResult("");
    setGender(e.target.value);
  };

  const handleAgeChange = (e) => {
    setResult("");
    setAge(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const calculateResult = () => {
    if (gender === "male") {
      if (age === "19") {
        if (value > 56) {
          setResult("Excellent");
        } else if (value >= 47 && value <= 56) {
          setResult("Good");
        } else if (value >= 35 && value <= 46) {
          setResult("Above Average");
        } else if (value >= 19 && value <= 34) {
          setResult("Average");
        } else if (value >= 11 && value <= 18) {
          setResult("Below Average");
        } else if (value >= 4 && value <= 10) {
          setResult("Poor");
        } else if (value <= 4) {
          setResult("Very Poor");
        }
      } else if (age === "29") {
        if (value > 47) {
          setResult("Excellent");
        } else if (value >= 39 && value <= 47) {
          setResult("Good");
        } else if (value >= 30 && value <= 38) {
          setResult("Above Average");
        } else if (value >= 17 && value <= 29) {
          setResult("Average");
        } else if (value >= 10 && value <= 16) {
          setResult("Below Average");
        } else if (value >= 4 && value <= 9) {
          setResult("Poor");
        } else if (value <= 4) {
          setResult("Very Poor");
        }
      } else if (age === "39") {
        if (value > 41) {
          setResult("Excellent");
        } else if (value >= 34 && value <= 41) {
          setResult("Good");
        } else if (value >= 25 && value <= 33) {
          setResult("Above Average");
        } else if (value >= 13 && value <= 24) {
          setResult("Average");
        } else if (value >= 18 && value <= 12) {
          setResult("Below Average");
        } else if (value >= 2 && value <= 7) {
          setResult("Poor");
        } else if (value <= 2) {
          setResult("Very Poor");
        }
      } else if (age === "49") {
        if (value > 34) {
          setResult("Excellent");
        } else if (value >= 28 && value <= 34) {
          setResult("Good");
        } else if (value >= 21 && value <= 27) {
          setResult("Above Average");
        } else if (value >= 11 && value <= 20) {
          setResult("Average");
        } else if (value >= 6 && value <= 10) {
          setResult("Below Average");
        } else if (value >= 1 && value <= 5) {
          setResult("Poor");
        } else if (value <= 0) {
          setResult("Very Poor");
        }
      } else if (age === "59") {
        if (value > 31) {
          setResult("Excellent");
        } else if (value >= 25 && value <= 31) {
          setResult("Good");
        } else if (value >= 18 && value <= 24) {
          setResult("Above Average");
        } else if (value >= 9 && value <= 17) {
          setResult("Average");
        } else if (value >= 5 && value <= 8) {
          setResult("Below Average");
        } else if (value >= 1 && value <= 4) {
          setResult("Poor");
        } else if (value <= 0) {
          setResult("Very Poor");
        }
      } else {
        if (value > 30) {
          setResult("Excellent");
        } else if (value >= 24 && value <= 30) {
          setResult("Good");
        } else if (value >= 17 && value <= 23) {
          setResult("Above Average");
        } else if (value >= 6 && value <= 16) {
          setResult("Average");
        } else if (value >= 3 && value <= 5) {
          setResult("Below Average");
        } else if (value >= 1 && value <= 2) {
          setResult("Poor");
        } else if (value === 0) {
          setResult("Very Poor");
        }
      }
    } else if (gender === "female") {
      if (age === "19") {
        if (value > 30) {
          setResult("Excellent");
        } else if (value >= 22 && value <= 30) {
          setResult("Good");
        } else if (value >= 11 && value <= 21) {
          setResult("Above Average");
        } else if (value >= 7 && value <= 10) {
          setResult("Average");
        } else if (value >= 4 && value <= 6) {
          setResult("Below Average");
        } else if (value >= 1 && value <= 3) {
          setResult("Poor");
        } else if (value <= 0) {
          setResult("Very Poor");
        }
      } else if (age === "29") {
        if (value > 432) {
          setResult("Excellent");
        } else if (value >= 24 && value <= 32) {
          setResult("Good");
        } else if (value >= 14 && value <= 23) {
          setResult("Above Average");
        } else if (value >= 9 && value <= 13) {
          setResult("Average");
        } else if (value >= 5 && value <= 8) {
          setResult("Below Average");
        } else if (value >= 1 && value <= 4) {
          setResult("Poor");
        } else if (value <= 0) {
          setResult("Very Poor");
        }
      } else if (age === "39") {
        if (value > 28) {
          setResult("Excellent");
        } else if (value >= 21 && value <= 28) {
          setResult("Good");
        } else if (value >= 13 && value <= 20) {
          setResult("Above Average");
        } else if (value >= 7 && value <= 12) {
          setResult("Average");
        } else if (value >= 3 && value <= 6) {
          setResult("Below Average");
        } else if (value >= 1 && value <= 2) {
          setResult("Poor");
        } else if (value <= 0) {
          setResult("Very Poor");
        }
      } else if (age === "49") {
        if (value > 20) {
          setResult("Excellent");
        } else if (value >= 15 && value <= 20) {
          setResult("Good");
        } else if (value >= 10 && value <= 14) {
          setResult("Above Average");
        } else if (value >= 5 && value <= 9) {
          setResult("Average");
        } else if (value >= 2 && value <= 4) {
          setResult("Below Average");
        } else if (value >= 1 && value <= 5) {
          setResult("Poor");
        } else if (value <= 0) {
          setResult("Very Poor");
        }
      } else if (age === "59") {
        if (value > 16) {
          setResult("Excellent");
        } else if (value >= 13 && value <= 16) {
          setResult("Good");
        } else if (value >= 9 && value <= 12) {
          setResult("Above Average");
        } else if (value >= 4 && value <= 8) {
          setResult("Average");
        } else if (value >= 2 && value <= 3) {
          setResult("Below Average");
        } else if (value >= 1 && value <= 4) {
          setResult("Poor");
        } else if (value <= 0) {
          setResult("Very Poor");
        }
      } else {
        if (value > 12) {
          setResult("Excellent");
        } else if (value >= 10 && value <= 12) {
          setResult("Good");
        } else if (value >= 6 && value <= 9) {
          setResult("Above Average");
        } else if (value >= 3 && value <= 5) {
          setResult("Average");
        } else if (value === 2) {
          setResult("Below Average");
        } else if (value === 1) {
          setResult("Poor");
        } else if (value <= 0) {
          setResult("Very Poor");
        }
      }
    }
  };

  return (
    <div className="grid  pb-4 grid-cols-1">
    
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col order-2 md:p-4">
          <h1 className="text-3xl mb-2 font-medium border-b-[1px] pb-4">
            Push Up Calculator
          </h1>
          <p>Calculate your push up score.</p>

          <label className=" text-gray-700 font-bold mb-2 mt-6">Gender </label>
          <div>
            <select className="" value={gender} onChange={handleGenderChange}>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <label className="block tracking-wide text-gray-700 font-bold mb-2 mt-8 ">
            Repetitions{" "}
          </label>
          <div className="slider-parent relative text-xl border-gray-300 mb-5">
            <input
              className="w-full mt-2"
              type="range"
              min="1"
              max="100"
              value={value}
              onChange={({ target: { value: radius } }) => {
                setValue(radius);
              }}
            />
            <div className="buble absolute text-sm font-bold text-red-500">
              ({value}) <span className="text-sm text-black">Reps</span>{" "}
            </div>
          </div>

          <label className="block tracking-wide text-gray-700 font-bold mb-2 mt-6 ">
            Age
          </label>
          <div>
            <select className="" value={age} onChange={handleAgeChange}>
              <option value="19">15-19 years old</option>
              <option value="29">20-29 years old</option>
              <option value="39">30-39 years old</option>
              <option value="49">40-49 years old</option>
              <option value="59">50-59 years old</option>
              <option value="60">60+ years old</option>
            </select>
          </div>

          <Link
            to="anchor"
            smooth={true}
            duration={500}
            className=" flex justify-center w-full lg:mb-4 shadow bg-blue-700 hover:bg-blue-600  focus:shadow-outline focus:outline-none text-white  py-3 px-6  rounded text-[1.2rem] mt-8 border-b-4 border-blue-900 mb-5"
            onClick={calculateResult}
          >
            Calculate Push up Score
          </Link>
        </div>
      </form>
      <div id="anchor">

      {result !== "" && (
        <div className="element flex flex-col order-1 p-4 ">
          <h2 className="text-3xl mb-2 font-medium border-b-[1px] pb-4">
            {result}
          </h2>

          {result === "Excellent" && (
            <span className="text-5xl md:text-5xl text-[#FFD603]">★★★★★</span>
          )}
          {result === "Good" && (
            <span className="text-5xl md:text-5xl text-[#FFD603]">★★★★</span>
          )}
          {result === "Above Average" && (
            <span className="text-5xl md:text-5xl text-[#FFD603]"> ★★★</span>
          )}
          {result === "Average" && (
            <span className="text-5xl md:text-5xl text-[#FFD603]"> ★★</span>
          )}
          {result === "Below Average" && (
            <span className="text-5xl md:text-5xl text-[#FFD603]">★</span>
          )}
          {result === "Poor" && (
            <span className="text-5xl md:text-5xl text-[#ff25033e]">
              No Stars
            </span>
          )}
          {result === "Very Poor" && (
            <span className="text-5xl md:text-5xl text-[#ff25033e]">
              No Stars
            </span>
          )}
        </div>
      )}
      </div>
    </div>
  );
};

export default PushUpsCalc;
