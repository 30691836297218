const PoundsTable = ({ exercise }) => {
  const { beginner, novice, intermediate, advance, elite } = exercise;
  return (
    <div className="container flex mx-auto max-w-[1000px] bg-white text-black p-6">
      <table className="table-fixed w-full shadow-md rounded border-slate-500">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-2 text-left font-bold">(lb)</th>
            <th className="p-2 text-left font-bold">Beg.</th>
            <th className="p-2 text-left font-bold">Nov.</th>
            <th className="p-2 text-left font-bold">Int.</th>
            <th className="p-2 text-left font-bold">Adv.</th>
            <th className="p-2 text-left font-bold">Elite</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-300">
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{90}</td>
            <td className="p-2">{Math.round(90 * beginner)}</td>
            <td className="p-2">{Math.round(90 * novice)}</td>
            <td className="p-2">{Math.round(90 * intermediate)}</td>
            <td className="p-2">{Math.round(90 * advance)}</td>
            <td className="p-2">{Math.round(90 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{100}</td>
            <td className="p-2">{Math.round(100 * beginner)}</td>
            <td className="p-2">{Math.round(100 * novice)}</td>
            <td className="p-2">{Math.round(100 * intermediate)}</td>
            <td className="p-2">{Math.round(100 * advance)}</td>
            <td className="p-2">{Math.round(100 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{110}</td>
            <td className="p-2">{Math.round(110 * beginner)}</td>
            <td className="p-2">{Math.round(110 * novice)}</td>
            <td className="p-2">{Math.round(110 * intermediate)}</td>
            <td className="p-2">{Math.round(110 * advance)}</td>
            <td className="p-2">{Math.round(110 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{120}</td>
            <td className="p-2">{Math.round(120 * beginner)}</td>
            <td className="p-2">{Math.round(120 * novice)}</td>
            <td className="p-2">{Math.round(120 * intermediate)}</td>
            <td className="p-2">{Math.round(120 * advance)}</td>
            <td className="p-2">{Math.round(120 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{130}</td>
            <td className="p-2">{Math.round(130 * beginner)}</td>
            <td className="p-2">{Math.round(130 * novice)}</td>
            <td className="p-2">{Math.round(130 * intermediate)}</td>
            <td className="p-2">{Math.round(130 * advance)}</td>
            <td className="p-2">{Math.round(130 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{140}</td>
            <td className="p-2">{Math.round(140 * beginner)}</td>
            <td className="p-2">{Math.round(140 * novice)}</td>
            <td className="p-2">{Math.round(140 * intermediate)}</td>
            <td className="p-2">{Math.round(140 * advance)}</td>
            <td className="p-2">{Math.round(140 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{150}</td>
            <td className="p-2">{Math.round(150 * beginner)}</td>
            <td className="p-2">{Math.round(150 * novice)}</td>
            <td className="p-2">{Math.round(150 * intermediate)}</td>
            <td className="p-2">{Math.round(150 * advance)}</td>
            <td className="p-2">{Math.round(150 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{160}</td>
            <td className="p-2">{Math.round(160 * beginner)}</td>
            <td className="p-2">{Math.round(160 * novice)}</td>
            <td className="p-2">{Math.round(160 * intermediate)}</td>
            <td className="p-2">{Math.round(160 * advance)}</td>
            <td className="p-2">{Math.round(160 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{170}</td>
            <td className="p-2">{Math.round(170 * beginner)}</td>
            <td className="p-2">{Math.round(170 * novice)}</td>
            <td className="p-2">{Math.round(170 * intermediate)}</td>
            <td className="p-2">{Math.round(170 * advance)}</td>
            <td className="p-2">{Math.round(170 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{180}</td>
            <td className="p-2">{Math.round(180 * beginner)}</td>
            <td className="p-2">{Math.round(180 * novice)}</td>
            <td className="p-2">{Math.round(180 * intermediate)}</td>
            <td className="p-2">{Math.round(180 * advance)}</td>
            <td className="p-2">{Math.round(180 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{190}</td>
            <td className="p-2">{Math.round(190 * beginner)}</td>
            <td className="p-2">{Math.round(190 * novice)}</td>
            <td className="p-2">{Math.round(190 * intermediate)}</td>
            <td className="p-2">{Math.round(190 * advance)}</td>
            <td className="p-2">{Math.round(190 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{200}</td>
            <td className="p-2">{Math.round(200 * beginner)}</td>
            <td className="p-2">{Math.round(200 * novice)}</td>
            <td className="p-2">{Math.round(200 * intermediate)}</td>
            <td className="p-2">{Math.round(200 * advance)}</td>
            <td className="p-2">{Math.round(200 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{220}</td>
            <td className="p-2">{Math.round(210 * beginner)}</td>
            <td className="p-2">{Math.round(210 * novice)}</td>
            <td className="p-2">{Math.round(210 * intermediate)}</td>
            <td className="p-2">{Math.round(210 * advance)}</td>
            <td className="p-2">{Math.round(210 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{220}</td>
            <td className="p-2">{Math.round(220 * beginner)}</td>
            <td className="p-2">{Math.round(220 * novice)}</td>
            <td className="p-2">{Math.round(220 * intermediate)}</td>
            <td className="p-2">{Math.round(220 * advance)}</td>
            <td className="p-2">{Math.round(220 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{230}</td>
            <td className="p-2">{Math.round(230 * beginner)}</td>
            <td className="p-2">{Math.round(230 * novice)}</td>
            <td className="p-2">{Math.round(230 * intermediate)}</td>
            <td className="p-2">{Math.round(230 * advance)}</td>
            <td className="p-2">{Math.round(230 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{240}</td>
            <td className="p-2">{Math.round(240 * beginner)}</td>
            <td className="p-2">{Math.round(240 * novice)}</td>
            <td className="p-2">{Math.round(240 * intermediate)}</td>
            <td className="p-2">{Math.round(240 * advance)}</td>
            <td className="p-2">{Math.round(240 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{250}</td>
            <td className="p-2">{Math.round(250 * beginner)}</td>
            <td className="p-2">{Math.round(250 * novice)}</td>
            <td className="p-2">{Math.round(250 * intermediate)}</td>
            <td className="p-2">{Math.round(250 * advance)}</td>
            <td className="p-2">{Math.round(250 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{260}</td>
            <td className="p-2">{Math.round(260 * beginner)}</td>
            <td className="p-2">{Math.round(260 * novice)}</td>
            <td className="p-2">{Math.round(260 * intermediate)}</td>
            <td className="p-2">{Math.round(260 * advance)}</td>
            <td className="p-2">{Math.round(260 * elite)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PoundsTable;
