import React, { useEffect, useState } from "react";
import OneRepTable from "../components/OneRepTable";

const OneRepCalc = () => {
  const [weight, setWeight] = useState("");
  const [oneRepMaxData, setOneRepMaxData] = useState("");
  const [reps, setReps] = useState(1);
  const [weightMeasurement, setWeightMeasurement] = useState("kg");
  const [show, setShow] = useState(false);
  const [formDisable, setFormDisable] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [show]);

  let calcOneRepMax = (e) => {
    e.preventDefault();
    if (reps < 2 && weight !== "") {
      setFormDisable(true);
      setShow(true);
      setOneRepMaxData(weight);
    } else if (reps > 1 && weight !== "") {
      setFormDisable(true);
      setShow(true);
      brzycki();
    }
  };

  let reload = () => {
    setShow(false);
    setFormDisable(false);
  };

  const brzycki = () => {
    let oneRepMax = weight * (36 / (37 - reps));
    setOneRepMaxData(oneRepMax);
  };

  const handleWeightChange = (e) => {
    setWeightMeasurement(e.target.value);
  };

  const handleRepsChange = (e) => {
    setReps(e.target.value);
  };

  return (
    <div className="grid  pb-4 md:m-4 md:mx-4 grid-cols-1">
      {!show && (
        <div>
          <h1 className="text-3xl font-medium mb-2 border-b-[1px] pb-4">
            1RM Calculator
          </h1>
          <p>
            {" "}
            Calculate your One Rep Max for any lift and plan out your training
            strategies!
          </p>
          <hr className="my-2" />

          <div className="flex">
            <h3>Instructions:</h3>
            <span
              className="text-blue-700 font-bold underline ml-2 cursor-pointer"
              onClick={() => setShowDetails(!showDetails)}
            >
              One-rep max calculator
            </span>
          </div>

          {showDetails && (
            <div className="bg-gray-100 p-4 rounded mt-3">
              <h2 className="font-bold">Steps</h2>
              <hr className="my-2" />

              <ul className="list-disc  ml-4 mt-2 text-gray-700">
                <li>Enter weight lifted </li>
                <li>Select number of reps </li>
              </ul>
              <hr className="my-2" />
              <div>
                <h2 className="font-bold pt-2">More Details</h2>

                <hr className="my-2" />

                <div>
                  <p>
                    The result will show you estimate for your 1RM if you have
                    select more than 1 rep.
                  </p>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={calcOneRepMax}>
            <div className="mb-4">
              <div className="flex mt-5 mb-2 justify-between items-center">
                <label className=" block tracking-wide text-gray-700  font-bold">
                  Weight ({weightMeasurement})
                </label>
              </div>

              <div className="flex">
                <div className="grow">
                  <input
                    className="rounded-none rounded-l-lg focus:border-r-2"
                    value={weight}
                    type="number"
                    required
                    placeholder="0"
                    disabled={formDisable}
                    onChange={(e) => setWeight(e.target.value)}
                  />
                </div>
                <div>
                  <select
                    className="rounded-none rounded-r-lg bg-gray-100"
                    value={weightMeasurement}
                    disabled={formDisable}
                    onChange={handleWeightChange}
                  >
                    <option value="lb">lb</option>
                    <option value="kg">kg</option>
                  </select>
                </div>
              </div>

              <div className="mt-4"></div>
              <label className="block  tracking-wide text-gray-700  font-bold mt-5 mb-2">
                Number of Reps
              </label>
              <div>
                <select
                  className="rounded-lg"
                  value={reps}
                  disabled={formDisable}
                  onChange={handleRepsChange}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
            </div>

            {!show && (
              <div className="flex justify-center w-full mb-0 lg:mb-4 shadow bg-blue-700 hover:bg-blue-600  focus:shadow-outline focus:outline-none text-white  py-3 px-6  rounded text-[1.2rem] mt-8 border-b-4 border-blue-900">
                <button className="flex" type="submit">
                  Calculate One Rep Max
                </button>
              </div>
            )}
          </form>
        </div>
      )}

      <div>
        {show === true && (
          <OneRepTable
            oneRepMaxData={oneRepMaxData}
            weightMeasurement={weightMeasurement}
          />
        )}
      </div>

      {show && (
        <button
  
        className=" flex justify-center w-full bg-transparent hover:bg-blue-500 text-blue-500 font-semibold hover:text-white py-3 px-6 border border-blue-500 hover:border-transparent rounded text-[1.2rem] mt-10"
        onClick={reload}
        >
          Edit Form
        </button>
      )}
    </div>
  );
};

export default OneRepCalc;
