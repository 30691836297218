import React, { useEffect, useState } from "react";
import { exercisesMale } from "../data/exercisesMale";
import { exercisesFemale } from "../data/exercisesFemale";
import classNames from "classnames";
import Select from "react-select";
import { exerciseNamesSearch } from "../data/exerciseNamesSearch";

const StrengthCalc = () => {
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("male");
  const [bodyWeight, setBodyWeight] = useState("");
  const [lifterGrade, setLifterGrade] = useState("");
  const [lifterPercentage, setLifterPercentage] = useState(0);
  const [oneRepMaxData, setOneRepMaxData] = useState(0);
  const [reps, setReps] = useState(1);
  const [weightMeasurement, setWeightMeasurement] = useState("kg");

  const [ex, setEx] = useState("bench press");
  const [data, setData] = useState(exercisesMale);
  const [formDisable, setFormDisable] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  let exercise = data.find((exercise) => exercise.name === ex);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let calcStrength = (e) => {
    e.preventDefault();
    if (reps < 2) {
      setFormDisable(true);
      setOneRepMaxData(weight);
      window.scrollTo(0, 0);
    } else if (reps > 1) {
      setFormDisable(true);
      brzycki();
      window.scrollTo(0, 0);
    }
  };

  const weightSwitch = () => {
    if (weightMeasurement === "kg") {
      return weight;
    } else if (weightMeasurement === "lb") {
      return weight;
    }
  };

  const brzycki = () => {
    let oneRepMax = weightSwitch(weight) * (36 / (37 - reps));
    setOneRepMaxData(oneRepMax);
  };

  const handleWeightChange = (e) => {
    setWeightMeasurement(e.target.value);
  };

  const handleRepsChange = (e) => {
    setReps(e.target.value);
  };

  const handleDataChange = (e) => {
    e.preventDefault();
    setGender(e.target.value);
  };

  useEffect(() => {
    if (gender === "male") {
      setData(exercisesMale);
    } else if (gender === "female") {
      setData(exercisesFemale);
    }
  }, [gender, setGender]);

  const handleExerciseChange = (value) => {
    setEx(value.value);
  };

  const beginner = exercise.beginner;
  const novice = exercise.novice;
  const intermediate = exercise.intermediate;
  const advance = exercise.advance;
  const elite = exercise.elite;

  useEffect(() => {
    if (lifterPercentage > 0 && lifterPercentage < 20) {
      setLifterGrade("Beginner");
    } else if (lifterPercentage > 19 && lifterPercentage < 50) {
      setLifterGrade("Novice");
    } else if (lifterPercentage > 49 && lifterPercentage < 80) {
      setLifterGrade("Intermediate");
    } else if (lifterPercentage > 79 && lifterPercentage < 95) {
      setLifterGrade("Advance");
    } else if (lifterPercentage > 94) {
      setLifterGrade("Elite");
    } else {
      setLifterGrade("Fuck you");
    }
  }, [lifterPercentage]);

  useEffect(() => {
    if (oneRepMaxData <= bodyWeight * beginner) {
      const percentageScore = oneRepMaxData / bodyWeight;
      const addPercent = 5 / beginner;
      setLifterPercentage((percentageScore * addPercent).toFixed(2));
    } else if (
      oneRepMaxData >= bodyWeight * beginner &&
      oneRepMaxData < bodyWeight * novice
    ) {
      const percentageScore = oneRepMaxData / bodyWeight - beginner;
      const addPercent = 15 / (novice - beginner);
      setLifterPercentage((percentageScore * addPercent + 5).toFixed(2));
    } else if (
      oneRepMaxData >= bodyWeight * novice &&
      oneRepMaxData < bodyWeight * intermediate
    ) {
      const percentageScore = oneRepMaxData / bodyWeight - novice;
      const addPercent = 30 / (intermediate - novice);
      setLifterPercentage((percentageScore * addPercent + 20).toFixed(2));
    } else if (
      oneRepMaxData >= bodyWeight * intermediate &&
      oneRepMaxData < bodyWeight * advance
    ) {
      const percentageScore = oneRepMaxData / bodyWeight - intermediate;
      const addPercent = 30 / (advance - intermediate);
      setLifterPercentage((percentageScore * addPercent + 50).toFixed(2));
    } else if (
      oneRepMaxData >= bodyWeight * advance &&
      oneRepMaxData < bodyWeight * elite
    ) {
      const percentageScore = oneRepMaxData / bodyWeight - advance;
      const addPercent = 15 / (elite - advance);
      setLifterPercentage((percentageScore * addPercent + 80).toFixed(2));
    } else {
      setLifterPercentage(95);
    }
  }, [
    oneRepMaxData,
    bodyWeight,
    beginner,
    novice,
    intermediate,
    advance,
    elite,
    lifterPercentage,
    weight,
    lifterGrade,
  ]);

  const resetForm = () => {
    setOneRepMaxData(0);
    setFormDisable(false);
  };

  const selectGradeBeginner = classNames(
    "text-black p-2 my-1 bg-gray-100 border-2",

    {
      "bg-blue-100  text-lg  border-blue-300 font-semibold":
        lifterGrade === "Beginner",
    }
  );

  const selectGradeNovice = classNames(
    "text-black p-2 my-1 bg-gray-100  border-2",

    {
      "bg-blue-100  text-lg  border-blue-300 font-semibold":
        lifterGrade === "Novice",
    }
  );

  const selectGradeIntermediate = classNames(
    "text-black p-2 my-1 bg-gray-100 border-2",

    {
      "bg-blue-100  text-lg  border-blue-300 font-semibold":
        lifterGrade === "Intermediate",
    }
  );

  const selectGradeAdvance = classNames(
    "text-black p-2 my-1 bg-gray-100 border-2",

    {
      "bg-blue-100  text-lg  border-blue-300 font-semibold":
        lifterGrade === "Advance",
    }
  );

  const selectGradeElite = classNames(
    "text-black p-2 my-1 bg-gray-100 border-2",

    {
      "bg-blue-100  text-lg  border-blue-300 font-semibold":
        lifterGrade === "Elite",
    }
  );

  return (
    
    <div className="grid  pb-4 md:m-4 md:mx-4 grid-cols-1 mb-10">
    
      {!formDisable && (
        <div>
          <h1 className="text-3xl font-medium mb-2 border-b-[1px] pb-4">
            Strength Calculator
          </h1>
          <p>
            {" "}
            Calculate your strength level. Get a percentage score to monitor
            progressive overload and help track your progress.
          </p>
          <hr className="my-2" />

          <div className="flex">
          <h3>Instructions:</h3><span className="text-blue-700 font-bold underline ml-2 cursor-pointer" onClick={() => setShowDetails(!showDetails)}> Strength calculator</span>
          </div>
          {showDetails && (
          <div className="bg-gray-100 p-4 rounded mt-3">
             <h2 className="font-bold">Steps</h2>
            <hr className="my-2" />
          
          
          <ul className="list-disc  ml-4 mt-2 text-gray-700">
            <li>Select gender </li>
            <li>Enter your body weight </li>
            <li>Select exercise </li>
            <li>Enter weight lifted </li>
            <li>Enter number of reps </li>
          </ul>
          <hr className="my-2" />
            <div>
            <h2 className="font-bold">More Details</h2>
            <hr className="my-2" />
              <div>
                <h3 className="font-semibold">Weight lifted</h3>
                <p>
                  Enter your one-rep max for the selected exercise.
                </p>
              </div>
              <hr className="my-2" />
              <div>
                <h3 className="font-semibold">Selecting the exercise</h3>
                <p>
              Choose the correct exercise based on your one-rep maximum. Dont use your bench press 1RM to compute you dumbbell bench press. each exercise has its own 1RM.


    
                </p>
              </div>
              <hr className="my-2" />

              <div>
                <h3 className="font-semibold">Number of reps</h3>

                <p>
                  If you don't know your one-rep max for the selected exercise you can change the number of reps to a workout set taken to failure.
                </p>
              </div>

            
            </div>
            </div>

          )}


          <form onSubmit={calcStrength}>
            <div className="mb-4">
              <label className="block  tracking-wide text-gray-700  font-bold mt-5 mb-2">
                Gender
              </label>
              <div>
                <select
                  className="rounded-lg"
                  value={gender}
                  onChange={handleDataChange}
                  disabled={formDisable}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>

              <div className="flex mt-2 mb-2 justify-between items-center">
                <label className=" block tracking-wide text-gray-700  font-bold">
                  Bodyweight
                </label>
              </div>

              <div className="flex">
                <div className="grow">
                  <input
                    className="rounded-none rounded-l-lg focus:border-r-2"
                    value={bodyWeight}
                    type="number"
                    name="quantity"
                    min="5"
                    max="400"
                    required
                    placeholder="0"
                    onChange={(e) => setBodyWeight(e.target.value)}
                    disabled={formDisable}
                  />
                </div>
                <div>
                  <select
                    className="rounded-none rounded-r-lg min-w-[95px] bg-gray-100 cursor-pointer"
                    value={weightMeasurement}
                    onChange={handleWeightChange}
                    disabled={formDisable}
                  >
                    <option value="lb">lb</option>
                    <option value="kg">kg</option>
                  </select>
                </div>
              </div>

              <div className="mb-2">
                <label className="block  tracking-wide text-gray-700  font-bold mt-2 mb-2">
                  Exercise
                </label>
                <div>
                  <Select
                    options={exerciseNamesSearch}
                    onChange={handleExerciseChange}
                    placeholder={ex}
                    values={ex}
                    isDisabled={formDisable}
                  />
                </div>
              </div>

              <div className="flex mt-2 mb-2 justify-between items-center">
                <label className=" block tracking-wide text-gray-700  font-bold">
                  Lift
                </label>
              </div>

              <div className="flex">
                <div className="grow">
                  <input
                    className="rounded-none rounded-l-lg focus:border-r-2"
                    value={weight}
                    type="number"
                    name="quantity"
                    min="1"
                    max="1500"
                    required
                    placeholder="0"
                    onChange={(e) => setWeight(e.target.value)}
                    disabled={formDisable}
                  />
                </div>
                <div>
                  <select
                    className="rounded-none rounded-r-lg min-w-[95px] bg-gray-100 cursor-pointer"
                    value={weightMeasurement}
                    onChange={handleWeightChange}
                    disabled={formDisable}
                  >
                    <option value="lb">lb</option>
                    <option value="kg">kg</option>
                  </select>
                </div>
              </div>

              <div className="mt-2"></div>
              <label className="block  tracking-wide text-gray-700  font-bold mt-2 mb-2">
                Number of Reps
              </label>
              <div>
                <select
                  className="rounded-lg"
                  value={reps}
                  onChange={handleRepsChange}
                  disabled={formDisable}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
            </div>
            {oneRepMaxData === 0 && (
              <button
                className=" flex justify-center w-full mb-0 lg:mb-4 shadow bg-blue-700 hover:bg-blue-600  focus:shadow-outline focus:outline-none text-white  py-3 px-6  rounded text-[1.2rem] mt-8 border-b-4 border-blue-900"
                type="submit"
              >
                Calculate Strength
              </button>
            )}
          </form>
        </div>
      )}

      {oneRepMaxData !== 0 && (
        <div>
          <div className=" bg-blue-700 mt-4 p-2 capitalize border-[1px] text-xl font-semibold text-white">
            {ex}
          </div>
          <div className="text-md bg-gray-100 mt-1 p-2 capitalize border-[1px]">
            Rating:
            {lifterGrade === "Elite" && (
              <span className="text-xl md:text-xl text-[#FFD603] pl-2 md:pl-0">
                ★★★★★
              </span>
            )}
            {lifterGrade === "Advance" && (
              <span className="text-xl md:text-xl text-[#FFD603] pl-2 md:pl-0">
                ★★★★
              </span>
            )}
            {lifterGrade === "Intermediate" && (
              <span className="text-xl md:text-xl text-[#FFD603] pl-2 md:pl-0">
                {" "}
                ★★★
              </span>
            )}
            {lifterGrade === "Novice" && (
              <span className="text-xl md:text-xl text-[#FFD603] pl-2 md:pl-0">
                {" "}
                ★★
              </span>
            )}
            {lifterGrade === "Beginner" && (
              <span className="text-xl md:text-xl text-[#FFD603] pl-2 md:pl-0">
                ★
              </span>
            )}
          </div>
          <div className="text-md bg-gray-100 mt-1 p-2 capitalize border-[1px]">
            Gender: <span className="font-bold">{gender}</span>
          </div>
          <div className="text-md bg-gray-100 mt-1 p-2 border-[1px]">
            {" "}
            Strength level: <span className="font-bold">{lifterGrade}</span>
          </div>
          <div className="text-md bg-gray-100 mt-1 p-2 border-[1px]">
            One rep max:{" "}
            <span className="font-bold">
              {Number(oneRepMaxData).toFixed()}
            </span>{" "}
           {weightMeasurement}
          </div>
          <div className="text-md bg-gray-100 mt-1 p-2 border-[1px]">
            Your Lift is:{" "}
            <span className="font-bold">
              {((weight / bodyWeight) * 1).toFixed(2)}
            </span>{" "}
            times your bodyweight
          </div>
          <div className="text-md bg-gray-100 mt-1 p-2 border-[1px]">
            Number of reps: <span className="font-bold">{reps}</span> reps.
          </div>
          <h3 className=" bg-blue-700 mt-4 p-2 capitalize border-[1px] text-xl font-semibold text-white">
            Progress Info
          </h3>

          <div className="text-md bg-gray-100 mt-1 p-2 border-[1px]">
            Progress percentage:{" "}
            <span className="font-bold">{lifterPercentage}</span>%
          </div>
          <h3 className=" bg-blue-700 mt-4  p-2 capitalize border-[1px] text-xl font-semibold text-white">
            Milestones
          </h3>
          <div className={selectGradeBeginner}>
            Beginner: {beginner * bodyWeight} <br></br>
          </div>
          <div className={selectGradeNovice}>Novice: {novice * bodyWeight}</div>
          <div className={selectGradeIntermediate}>
            Intermediate: {intermediate * bodyWeight}
          </div>
          <div className={selectGradeAdvance}>
            Advance: {advance * bodyWeight}
          </div>
          <div className={selectGradeElite}>Elite: {elite * bodyWeight}</div>
        </div>
      )}

      {oneRepMaxData > 0 && bodyWeight > 0 && (
        <div>
          <button
            className=" flex justify-center w-full bg-transparent hover:bg-blue-500 text-blue-500 font-semibold hover:text-white py-3 px-6 border border-blue-500 hover:border-transparent rounded text-[1.2rem] mt-10"
            onClick={resetForm}
          >
            Edit Form
          </button>
        </div>
      )}
    </div>
  );
};

export default StrengthCalc;
