import React from "react";

const OneRepTable = ({ oneRepMaxData, weightMeasurement }) => {
  return (
    <table className="table-fixed w-full shadow-md rounded border-slate-500 mt-8">
      <thead className="bg-blue-700 text-white">
        <tr>
          <th className="p-2 text-left font-bold">Repetition</th>
          <th className="p-2 text-left font-bold">Weight</th>
          <th className="p-2 text-left font-bold">% of 1RM</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-400">
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">1</td>
          <td className="p-2">
            {Number((oneRepMaxData * 1).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">100%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">2</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.95).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">95%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">3</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.93).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">93%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">4</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.9).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">90%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">5</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.87).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">87%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">6</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.85).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">85%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">7</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.83).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">83%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">8</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.80).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">80%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">9</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.77).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">77%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">10</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.75).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">75%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">11</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.73).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">73%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">12</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.7).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">70%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">16</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.65).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">65%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">20</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.6).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">60%</td>
        </tr>
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">24</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.55).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">55%</td>
        </tr>

        <tr className="even:bg-[#FAFAFA] odd:bg-white">
          <td className="p-2">30</td>
          <td className="p-2">
            {Number((oneRepMaxData * 0.5).toFixed(1))} {weightMeasurement}
          </td>
          <td className="p-2">50%</td>
        </tr>
      </tbody>
    </table>
  );
};

export default OneRepTable;
