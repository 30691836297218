export const exercisesFemale = [
  {
    name: "bench press",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 0.75,
    advance: 1.0,
    elite: 1.5,
  },
  {
    name: "squat",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.25,
    advance: 1.5,
    elite: 2.0,
  },
  {
    name: "deadlift",
    beginner: 0.5,
    novice: 1.0,
    intermediate: 1.25,
    advance: 1.75,
    elite: 2.5,
  },
  {
    name: "shoulder press",
    beginner: 0.2,
    novice: 0.35,
    intermediate: 0.5,
    advance: 0.75,
    elite: 1.0,
  },
  {
    name: "bent over row",
    beginner: 0.25,
    novice: 0.4,
    intermediate: 0.65,
    advance: 0.9,
    elite: 1.2,
  },
  {
    name: "dumbbell curl",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.35,
    elite: 0.45,
  },
  {
    name: "dumbbell bench press",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.5,
    elite: 0.7,
  },
  {
    name: "barbell curl",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.4,
    advance: 0.6,
    elite: 0.85,
  },

  {
    name: "front squat",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.25,
    elite: 1.5,
  },
  {
    name: "incline bench press",
    beginner: 0.2,
    novice: 0.4,
    intermediate: 0.65,
    advance: 1.0,
    elite: 1.4,
  },
  {
    name: "dumbbell shoulder press",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.35,
    elite: 0.5,
  },
  {
    name: "sled leg press",
    beginner: 0.5,
    novice: 1.25,
    intermediate: 2.0,
    advance: 3.25,
    elite: 4.5,
  },
  {
    name: "power clean",
    beginner: 0.4,
    novice: 0.6,
    intermediate: 0.85,
    advance: 1.1,
    elite: 1.4,
  },

  {
    name: "incline dumbbell bench press",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.45,
    elite: 0.6,
  },

  {
    name: "lat pulldown",
    beginner: 0.3,
    novice: 0.45,
    intermediate: 0.7,
    advance: 0.95,
    elite: 1.3,
  },

  {
    name: "hex bar deadlift",
    beginner: 0.75,
    novice: 1.0,
    intermediate: 1.5,
    advance: 2.0,
    elite: 2.5,
  },
  {
    name: "military press",
    beginner: 0.2,
    novice: 0.35,
    intermediate: 0.55,
    advance: 0.75,
    elite: 1.0,
  },
  {
    name: "sumo deadlift",
    beginner: 0.75,
    novice: 1.0,
    intermediate: 1.5,
    advance: 2.0,
    elite: 2.5,
  },

  {
    name: "hip thrust",
    beginner: 0.5,
    novice: 1.0,
    intermediate: 1.5,
    advance: 2.25,
    elite: 3.0,
  },

  {
    name: "horizontal leg press",
    beginner: 0.5,
    novice: 1.0,
    intermediate: 1.75,
    advance: 2.75,
    elite: 3.75,
  },

  {
    name: "dumbbell row",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.35,
    advance: 0.5,
    elite: 0.65,
  },

  {
    name: "dumbbell lateral raise",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.15,
    advance: 0.2,
    elite: 0.3,
  },

  {
    name: "romanian deadlift",
    beginner: 0.75,
    novice: 1.0,
    intermediate: 1.5,
    advance: 2.0,
    elite: 2.75,
  },

  {
    name: "clean and jerk",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.25,
    advance: 1.5,
    elite: 2.0,
  },

  {
    name: "snatch",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.25,
    elite: 1.75,
  },

  {
    name: "seated cable row",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.0,
  },
  {
    name: "clean",
    beginner: 0.75,
    novice: 1.0,
    intermediate: 1.25,
    advance: 1.5,
    elite: 2.0,
  },

  {
    name: "hammer curl",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.45,
    elite: 0.6,
  },

  {
    name: "push press",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.5,
    elite: 1.75,
  },

  {
    name: "barbell shrug",
    beginner: 0.5,
    novice: 1.0,
    intermediate: 1.5,
    advance: 2.25,
    elite: 3.25,
  },

  {
    name: "decline bench press",
    beginner: 0.5,
    novice: 1.0,
    intermediate: 1.25,
    advance: 1.75,
    elite: 2.25,
  },

  {
    name: "dumbbell fly",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.3,
    advance: 0.5,
    elite: 0.7,
  },

  {
    name: "t bar row",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.0,
  },

  {
    name: "dumbbell lunge",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.4,
    advance: 0.6,
    elite: 0.85,
  },

  {
    name: "lying tricep extension",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.35,
    advance: 0.55,
    elite: 0.75,
  },

  {
    name: "Seated Shoulder Press",
    beginner: 0.1,
    novice: 0.25,
    intermediate: 0.5,
    advance: 0.75,
    elite: 1.05,
  },

  {
    name: "tricep rope pushdown",
    beginner: 0.2,
    novice: 0.25,
    intermediate: 0.4,
    advance: 0.6,
    elite: 0.85,
  },
  {
    name: "dumbbell shrug",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.4,
    advance: 0.65,
    elite: 0.95,
  },
  {
    name: "goblet squat",
    beginner: 0.15,
    novice: 0.25,
    intermediate: 0.4,
    advance: 0.6,
    elite: 0.85,
  },
  {
    name: "dumbbell tricep extension",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.45,
  },

  {
    name: "rack pull",
    beginner: 0.75,
    novice: 1.25,
    intermediate: 1.75,
    advance: 2.25,
    elite: 3.0,
  },

  {
    name: "preacher curl",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.4,
    advance: 0.6,
    elite: 10.85,
  },

  {
    name: "dumbbell bulgarian split squat",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.45,
    elite: 0.6,
  },

  {
    name: "pendlay row",
    beginner: 0.4,
    novice: 0.55,
    intermediate: 0.8,
    advance: 1.05,
    elite: 1.35,
  },

  {
    name: "vertical leg press",
    beginner: 0.75,
    novice: 1.25,
    intermediate: 2.25,
    advance: 3.25,
    elite: 4.5,
  },

  {
    name: "machine shoulder press",
    beginner: 0.1,
    novice: 0.25,
    intermediate: 0.5,
    advance: 0.85,
    elite: 1.2,
  },

  {
    name: "chest press",
    beginner: 0.15,
    novice: 0.3,
    intermediate: 0.55,
    advance: 0.9,
    elite: 1.25,
  },

  {
    name: "machine chest fly",
    beginner: 0.15,
    novice: 0.35,
    intermediate: 0.6,
    advance: 0.9,
    elite: 1.3,
  },

  {
    name: "machine calf raise",
    beginner: 0.25,
    novice: 0.75,
    intermediate: 1.25,
    advance: 2.25,
    elite: 3.25,
  },
  {
    name: "lying leg curl",
    beginner: 0.2,
    novice: 0.4,
    intermediate: 0.6,
    advance: 0.85,
    elite: 1.15,
  },

  {
    name: "hack squat",
    beginner: 0.25,
    novice: 0.75,
    intermediate: 1.5,
    advance: 2.25,
    elite: 3.25,
  },

  {
    name: "upright row",
    beginner: 0.15,
    novice: 0.3,
    intermediate: 0.5,
    advance: 0.75,
    elite: 1.05,
  },

  {
    name: "tricep extension",
    beginner: 0.05,
    novice: 0.2,
    intermediate: 0.35,
    advance: 0.6,
    elite: 0.85,
  },

  {
    name: "box squat",
    beginner: 0.5,
    novice: 1.0,
    intermediate: 1.25,
    advance: 1.75,
    elite: 2.5,
  },

  {
    name: "dumbbell front raise",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.15,
    advance: 0.25,
    elite: 0.4,
  },

  {
    name: "dumbbell concentration curl",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.45,
  },

  {
    name: "cable bicep curl",
    beginner: 0.15,
    novice: 0.35,
    intermediate: 0.65,
    advance: 1.05,
    elite: 1.5,
  },
  {
    name: "cable lateral raise",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.15,
    advance: 0.25,
    elite: 0.35,
  },

  {
    name: "arnold press",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.35,
  },

  {
    name: "bulgarian split squat",
    beginner: 0.15,
    novice: 0.3,
    intermediate: 0.55,
    advance: 0.85,
    elite: 1.25,
  },

  {
    name: "floor press",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 0.75,
    advance: 1.25,
    elite: 1.5,
  },

  {
    name: "incline dumbbell fly",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.35,
    elite: 0.5,
  },

  {
    name: "good morning",
    beginner: 0.2,
    novice: 0.4,
    intermediate: 0.7,
    advance: 1.05,
    elite: 1.45,
  },

  {
    name: "hang clean",
    beginner: 0.45,
    novice: 0.6,
    intermediate: 0.8,
    advance: 1.05,
    elite: 1.3,
  },

  {
    name: "seated calf raise",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 1.0,
    advance: 1.75,
    elite: 2.5,
  },

  {
    name: "seated leg curl",
    beginner: 0.25,
    novice: 0.45,
    intermediate: 0.75,
    advance: 1.05,
    elite: 1.45,
  },

  {
    name: "cable fly",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.3,
    advance: 0.55,
    elite: 0.8,
  },

  {
    name: "stiff leg deadlift",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.0,
  },

  {
    name: "smith machine bench press",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 0.75,
    advance: 1.25,
    elite: 1.5,
  },

  {
    name: "dumbbell romanian deadlift",
    beginner: 0.15,
    novice: 0.25,
    intermediate: 0.4,
    advance: 0.6,
    elite: 0.8,
  },

  {
    name: "zercher squat",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 1.0,
    advance: 1.5,
    elite: 1.75,
  },

  {
    name: "dumbbell pullover",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.45,
    elite: 0.65,
  },

  {
    name: "lying dumbbell tricep extension",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.35,
    elite: 0.5,
  },

  {
    name: "overhead squat",
    beginner: 0.15,
    novice: 0.45,
    intermediate: 0.7,
    advance: 1.0,
    elite: 1.3,
  },

  {
    name: "cable crunch",
    beginner: 0.2,
    novice: 0.4,
    intermediate: 0.7,
    advance: 1.05,
    elite: 1.45,
  },

  {
    name: "face pull",
    beginner: 0.15,
    novice: 0.3,
    intermediate: 0.5,
    advance: 0.8,
    elite: 1.1,
  },

  {
    name: "incline dumbbell curl",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.45,
  },

  {
    name: "hip abduction",
    beginner: 0.25,
    novice: 0.75,
    intermediate: 1.25,
    advance: 1.75,
    elite: 2.25,
  },

  {
    name: "dumbbell floor press",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.465,
    elite: 0.65,
  },

  {
    name: "machine row",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 0.75,
    advance: 1.25,
    elite: 1.75,
  },

  {
    name: "dumbbell reverse fly",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.15,
    advance: 0.25,
    elite: 0.4,
  },

  {
    name: "thruster",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 0.7,
    advance: 0.95,
    elite: 1.25,
  },

  {
    name: "smith machine squat",
    beginner: 0.25,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.25,
  },

  {
    name: "wrist curl",
    beginner: 0,
    novice: 0.25,
    intermediate: 0.5,
    advance: 1.0,
    elite: 1.75,
  },

  {
    name: "push jerk",
    beginner: 0.45,
    novice: 0.6,
    intermediate: 0.8,
    advance: 1.05,
    elite: 1.3,
  },

  {
    name: "power snatch",
    beginner: 0.3,
    novice: 0.45,
    intermediate: 0.65,
    advance: 0.85,
    elite: 1.1,
  },

  {
    name: "hip adduction",
    beginner: 0.25,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.25,
  },

  {
    name: "dumbbell tricep kickback",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.15,
    advance: 0.25,
    elite: 0.35,
  },

  {
    name: "chest supported dumbbell row",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.35,
    advance: 0.5,
    elite: 0.65,
  },

  {
    name: "seated dip machine",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 0.75,
    advance: 1.25,
    elite: 1.5,
  },

  {
    name: "decline dumbbell bench press",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.35,
    advance: 0.55,
    elite: 0.75,
  },

  {
    name: "split squat",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 0.75,
    advance: 1.25,
    elite: 1.5,
  },

  {
    name: "machine seated crunch",
    beginner: 0.2,
    novice: 0.35,
    intermediate: 0.6,
    advance: 0.85,
    elite: 1.15,
  },

  {
    name: "cable pull through",
    beginner: 0.2,
    novice: 0.4,
    intermediate: 0.6,
    advance: 1.0,
    elite: 1.5,
  },

  {
    name: "reverse grip bench press",
    beginner: 0.2,
    novice: 0.35,
    intermediate: 0.6,
    advance: 0.85,
    elite: 1.2,
  },
  {
    name: "reverse wrist curl",
    beginner: 0.0,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.35,
    elite: 0.5,
  },

  {
    name: "barbell calf raise",
    beginner: 0.25,
    novice: 0.75,
    intermediate: 1.25,
    advance: 2.0,
    elite: 2.75,
  },

  {
    name: "barbell lunge",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 0.75,
    advance: 1.25,
    elite: 1.5,
  },

  {
    name: "landmine squat",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 0.75,
    advance: 1.25,
    elite: 1.5,
  },

  {
    name: "standing leg curl",
    beginner: 0.2,
    novice: 0.45,
    intermediate: 0.65,
    advance: 0.95,
    elite: 1.3,
  },

  {
    name: "reverse barbell curl",
    beginner: 0.0,
    novice: 0.15,
    intermediate: 0.4,
    advance: 0.65,
    elite: 1.2,
  },

  {
    name: "one arm cable bicep curl",
    beginner: 0.0,
    novice: 0.1,
    intermediate: 0.25,
    advance: 0.5,
    elite: 0.8,
  },

  {
    name: "dumbbell squat",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.35,
    advance: 0.55,
    elite: 0.75,
  },

  {
    name: "barbell glute bridge",
    beginner: 0.4,
    novice: 1.0,
    intermediate: 1.5,
    advance: 2.25,
    elite: 3.0,
  },

  {
    name: "barbell reverse lunge",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 1.0,
    advance: 1.25,
    elite: 1.5,
  },

  {
    name: "dumbbell calf raise",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.35,
    advance: 0.5,
    elite: 0.7,
  },

  {
    name: "machine tricep extension",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.35,
    advance: 0.55,
    elite: 0.8,
  },

  {
    name: "deficit deadlift",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.25,
    advance: 1.75,
    elite: 2.25,
  },

  {
    name: "close grip lat pulldown",
    beginner: 0.4,
    novice: 0.55,
    intermediate: 0.8,
    advance: 1.1,
    elite: 1.4,
  },

  {
    name: "cable crossover",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.5,
    elite: 0.7,
  },

  {
    name: "paused bench press",
    beginner: 0.25,
    novice: 0.45,
    intermediate: 0.7,
    advance: 1.05,
    elite: 1.45,
  },

  {
    name: "cable overhead tricep extension",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.35,
    advance: 0.6,
    elite: 0.85,
  },

  {
    name: "strict curl",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.4,
    advance: 0.6,
    elite: 0.85,
  },

  {
    name: "dumbbell deadlift",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.4,
    advance: 0.6,
    elite: 0.85,
  },

  {
    name: "neck curl",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.4,
    elite: 0.55,
  },

  {
    name: "cable reverse fly",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.35,
    elite: 0.55,
  },

  {
    name: "belt squat",
    beginner: 0.15,
    novice: 0.35,
    intermediate: 0.55,
    advance: 0.85,
    elite: 1.2,
  },
  {
    name: "dumbbell upright row",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.35,
    elite: 0.5,
  },

  {
    name: "machine bicep curl",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.4,
    advance: 0.7,
    elite: 1,
  },

  {
    name: "bench pin press",
    beginner: 0.2,
    novice: 0.35,
    intermediate: 0.6,
    advance: 0.9,
    elite: 1.25,
  },

  {
    name: "barbell hack squat",
    beginner: 0.3,
    novice: 0.5,
    intermediate: 0.75,
    advance: 1.05,
    elite: 1.4,
  },

  {
    name: "machine reverse fly",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.35,
    elite: 0.55,
  },

  {
    name: "safety bar squat",
    beginner: 0.5,
    novice: 1.0,
    intermediate: 1.25,
    advance: 1.75,
    elite: 2.25,
  },

  {
    name: "barbell front raise",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.45,
    elite: 0.65,
  },

  {
    name: "reverse grip lat pulldown",
    beginner: 0.15,
    novice: 0.3,
    intermediate: 0.6,
    advance: 0.95,
    elite: 1.4,
  },

  {
    name: "single leg press",
    beginner: 0.25,
    novice: 0.75,
    intermediate: 1.25,
    advance: 2.0,
    elite: 2.75,
  },

  {
    name: "dumbbell wrist curl",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.4,
  },

  {
    name: "behind the neck press",
    beginner: 0.2,
    novice: 0.35,
    intermediate: 0.5,
    advance: 0.75,
    elite: 0.95,
  },

  {
    name: "close grip bench press",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.25,
    advance: 1.5,
    elite: 2.0,
  },

  {
    name: "close grip dumbbell bench press",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.4,
    elite: 0.55,
  },

  {
    name: "log press",
    beginner: 0.15,
    novice: 0.3,
    intermediate: 0.5,
    advance: 0.75,
    elite: 1.05,
  },

  {
    name: "bench pull",
    beginner: 0.15,
    novice: 0.3,
    intermediate: 0.5,
    advance: 0.75,
    elite: 1.0,
  },

  {
    name: "pause squat",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.25,
    advance: 1.5,
    elite: 2.0,
  },

  {
    name: "clean high pull",
    beginner: 0.45,
    novice: 0.65,
    intermediate: 0.85,
    advance: 1.1,
    elite: 1.35,
  },

  {
    name: "sled press calf raise",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 1.0,
    advance: 1.75,
    elite: 2.5,
  },

  {
    name: "straight arm pulldown",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.4,
    advance: 0.65,
    elite: 0.95,
  },

  {
    name: "single leg romanian deadlift",
    beginner: 0.15,
    novice: 0.3,
    intermediate: 0.5,
    advance: 0.7,
    elite: 0.95,
  },

  {
    name: "cable woodchoppers",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.35,
    elite: 0.55,
  },

  {
    name: "clean pull",
    beginner: 0.45,
    novice: 0.65,
    intermediate: 0.85,
    advance: 1.1,
    elite: 1.35,
  },

  {
    name: "split jerk",
    beginner: 0.5,
    novice: 0.65,
    intermediate: 0.9,
    advance: 1.15,
    elite: 1.4,
  },

  {
    name: "dumbbell preacher curl",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.45,
  },

  {
    name: "sumo squat",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 0.75,
    advance: 1.25,
    elite: 2.0,
  },

  {
    name: "snatch deadlift",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.25,
    elite: 1.75,
  },

  {
    name: "incline hammer curl",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.4,
  },

  {
    name: "dumbbell side bend",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.3,
    advance: 0.45,
    elite: 0.7,
  },
  {
    name: "machine back extension",
    beginner: 0.2,
    novice: 0.4,
    intermediate: 0.65,
    advance: 0.95,
    elite: 1.35,
  },

  {
    name: "seated shoulder press",
    beginner: 0.1,
    novice: 0.25,
    intermediate: 0.5,
    advance: 0.75,
    elite: 1.05,
  },

  {
    name: "hang power clean",
    beginner: 0.45,
    novice: 0.6,
    intermediate: 0.8,
    advance: 1.0,
    elite: 1.2,
  },

  {
    name: "yates row",
    beginner: 0.3,
    novice: 0.5,
    intermediate: 0.7,
    advance: 1.0,
    elite: 1.3,
  },

  {
    name: "dumbbell snatch",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.3,
    advance: 0.45,
    elite: 0.65,
  },

  {
    name: "jefferson deadlift",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.0,
  },

  {
    name: "barbell pullover",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.45,
  },

  {
    name: "machine lateral raise",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.35,
    advance: 0.5,
    elite: 0.7,
  },

  {
    name: "pause deadlift",
    beginner: 0.25,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.0,
  },

  {
    name: "single leg deadlift",
    beginner: 0.15,
    novice: 0.25,
    intermediate: 0.45,
    advance: 0.65,
    elite: 0.9,
  },

  {
    name: "landmine press",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.45,
  },

  {
    name: "muscle snatch",
    beginner: 0.25,
    novice: 0.4,
    intermediate: 0.6,
    advance: 0.8,
    elite: 1.05,
  },

  {
    name: "wide grip bench press",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 0.75,
    advance: 1.25,
    elite: 1.5,
  },

  {
    name: "reverse grip tricep pushdown",
    beginner: 0.1,
    novice: 0.25,
    intermediate: 0.5,
    advance: 0.85,
    elite: 1.25,
  },
  {
    name: "tricep pushdown",
    beginner: 0.15,
    novice: 0.25,
    intermediate: 0.5,
    advance: 0.75,
    elite: 1.05,
  },

  {
    name: "cable kickback",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.4,
    elite: 0.6,
  },

  {
    name: "dumbbell bench pull",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.4,
    elite: 0.55,
  },

  {
    name: "z press",
    beginner: 0.1,
    novice: 0.25,
    intermediate: 0.45,
    advance: 0.65,
    elite: 0.9,
  },

  {
    name: "neck extension",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.15,
    advance: 0.25,
    elite: 0.4,
  },

  {
    name: "reverse curl",
    beginner: 0.1,
    novice: 0.25,
    intermediate: 0.4,
    advance: 0.6,
    elite: 0.8,
  },

  {
    name: "renegade row",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.35,
    elite: 0.45,
  },

  {
    name: "zottman curl",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.45,
  },

  {
    name: "shoulder pin press",
    beginner: 0.15,
    novice: 0.3,
    intermediate: 0.5,
    advance: 0.75,
    elite: 1.0,
  },

  {
    name: "viking press",
    beginner: 0.2,
    novice: 0.35,
    intermediate: 0.5,
    advance: 0.75,
    elite: 1.0,
  },

  {
    name: "tate press",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.15,
    advance: 0.25,
    elite: 0.4,
  },

  {
    name: "snatch pull",
    beginner: 0.4,
    novice: 0.55,
    intermediate: 0.75,
    advance: 0.95,
    elite: 1.2,
  },

  {
    name: "spider curl",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.35,
    advance: 0.55,
    elite: 0.88,
  },

  {
    name: "jm press",
    beginner: 0.1,
    novice: 0.25,
    intermediate: 0.45,
    advance: 0.7,
    elite: 0.95,
  },

  {
    name: "dumbbell z press",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.2,
    advance: 0.35,
    elite: 0.45,
  },

  {
    name: "pin squat",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.5,
    elite: 1.75,
  },

  {
    name: "bent over dumbbell row",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.5,
    elite: 0.7,
  },

  {
    name: "seated dumbbell shoulder press",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.35,
    elite: 0.5,
  },
  {
    name: "bent over dumbbell row",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.5,
    elite: 0.7,
  },

  {
    name: "cheat curl",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.4,
    advance: 0.6,
    elite: 0.85,
  },

  {
    name: "seated dumbbell curl",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.4,
  },

  {
    name: "wall ball",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.4,
  },

  {
    name: "dumbbell front squat",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.45,
    elite: 0.6,
  },

  {
    name: "walking lunge",
    beginner: 0.3,
    novice: 0.5,
    intermediate: 0.8,
    advance: 1.1,
    elite: 1.5,
  },

  {
    name: "close grip incline bench press",
    beginner: 0.25,
    novice: 0.4,
    intermediate: 0.6,
    advance: 0.85,
    elite: 1.15,
  },

  {
    name: "cable upright row",
    beginner: 0.15,
    novice: 0.3,
    intermediate: 0.5,
    advance: 0.75,
    elite: 1.05,
  },

  {
    name: "dumbbell reverse wrist curl",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.4,
  },

  {
    name: "one arm lat pulldown",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.45,
    elite: 0.6,
  },

  {
    name: "dumbbell push press",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.35,
    elite: 0.5,
  },

  {
    name: "one arm seated cable row",
    beginner: 0.15,
    novice: 0.25,
    intermediate: 0.45,
    advance: 0.65,
    elite: 0.85,
  },

  {
    name: "dumbbell external rotation",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.15,
    advance: 0.25,
    elite: 0.35,
  },

  {
    name: "dumbbell split squat",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.3,
    advance: 0.4,
    elite: 0.6,
  },

  {
    name: "smith machine shrug",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.25,
  },

  {
    name: "dumbbell incline y raise",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.45,
  },

  {
    name: "single arm landmine press",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.45,
  },

  {
    name: "hex bar shrug",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.25,
  },

  {
    name: "barbell power shrug",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.25,
  },

  {
    name: "dumbbell reverse curl",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.45,
  },

  {
    name: "cable hammer curl",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.4,
  },

  {
    name: "single leg seated calf raise",
    beginner: 0.1,
    novice: 0.3,
    intermediate: 0.6,
    advance: 1.0,
    elite: 1.45,
  },

  {
    name: "cable leg extension",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.45,
    elite: 0.65,
  },

  {
    name: "leg extension",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 1.0,
    advance: 1.25,
    elite: 2.0,
  },

  {
    name: "machine shrug",
    beginner: 0.15,
    novice: 0.35,
    intermediate: 0.65,
    advance: 1.0,
    elite: 1.45,
  },

  {
    name: "decline dumbbell fly",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.45,
  },

  {
    name: "single leg dumbbell deadlift",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.35,
    advance: 0.55,
    elite: 0.75,
  },

  {
    name: "zercher deadlift",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.25,
    elite: 1.5,
  },

  {
    name: "one arm pulldown",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.35,
    elite: 0.5,
  },

  {
    name: "dumbbell high pull",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.35,
    elite: 0.5,
  },

  {
    name: "dumbbell face pull",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.4,
  },

  {
    name: "paused squat",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.5,
    elite: 1.75,
  },

  {
    name: "cable external rotation",
    beginner: 0.05,
    novice: 0.1,
    intermediate: 0.2,
    advance: 0.35,
    elite: 0.5,
  },

  {
    name: "dumbbell clean and press",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.35,
    elite: 0.5,
  },

  {
    name: "clean and press",
    beginner: 0.25,
    novice: 0.4,
    intermediate: 0.65,
    advance: 0.9,
    elite: 1.2,
  },

  {
    name: "ez bar curl",
    beginner: 0.15,
    novice: 0.3,
    intermediate: 0.4,
    advance: 0.6,
    elite: 0.8,
  },

  {
    name: "half squat",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.25,
    advance: 1.75,
    elite: 2.25,
  },

  {
    name: "dumbbell hang clean",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.4,
    elite: 0.55,
  },

  {
    name: "cable shrug",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 0.75,
    advance: 1.25,
    elite: 1.75,
  },

  {
    name: "hang snatch",
    beginner: 0.35,
    novice: 0.5,
    intermediate: 0.7,
    advance: 0.95,
    elite: 1.25,
  },

  {
    name: "jefferson squat",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.0,
  },

  {
    name: "bent arm barbell pullover",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.2,
    advance: 0.3,
    elite: 0.45,
  },

  {
    name: "dumbbell thruster",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.35,
    elite: 0.5,
  },

  {
    name: "overhead cable curl",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.4,
    advance: 0.65,
    elite: 0.95,
  },
  {
    name: "meadows row",
    beginner: 0.1,
    novice: 0.2,
    intermediate: 0.3,
    advance: 0.45,
    elite: 0.65,
  },

  {
    name: "standing cable crunch",
    beginner: 0.1,
    novice: 0.3,
    intermediate: 0.5,
    advance: 65,
    elite: 1.0,
  },

  {
    name: "spoto press",
    beginner: 0.25,
    novice: 0.45,
    intermediate: 0.7,
    advance: 1.05,
    elite: 1.45,
  },

  {
    name: "behind the back barbell shrug",
    beginner: 0.25,
    novice: 0.5,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.25,
  },

  {
    name: "behind the back deadlift",
    beginner: 0.5,
    novice: 0.75,
    intermediate: 1.0,
    advance: 1.5,
    elite: 2.0,
  },

  {
    name: "dumbbell walking calf raise",
    beginner: 0.1,
    novice: 0.15,
    intermediate: 0.25,
    advance: 0.4,
    elite: 0.55,
  },

  {
    name: "lying cable curl",
    beginner: 0.1,
    novice: 0.25,
    intermediate: 0.45,
    advance: 0.75,
    elite: 1.1,
  },

  {
    name: "incline cable curl",
    beginner: 0.05,
    novice: 0.15,
    intermediate: 0.3,
    advance: 0.5,
    elite: 0.75,
  },

  {
    name: "high pulley crunch",
    beginner: 0.2,
    novice: 0.3,
    intermediate: 0.5,
    advance: 0.75,
    elite: 1.0,
  },
];
