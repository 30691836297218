import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navbar = ({ setCalculators, calculators }) => {

  const handleCalculators = () => {
    setCalculators(!calculators);
  };

  const shutCalculator = () => {
    return setCalculators(false);
  };

  
  return (
    <div className="max-w-[1000px] m-auto rounded-xl">

      <div className="container flex items-stretch mx-auto h-10 max-w-[1000px]  text-white mt-2 text-[1rem] shadow-xl opacity-70">
        {!calculators && (
          <div
            onClick={handleCalculators}
            className="text-black  bg-gray-300 flex w-[30%] md:w-1/3 items-center justify-center cursor-pointer lg:rounded-l-lg"
          >
            Apps <FaChevronDown className="ml-[2px] mt-[2px]" />
          </div>
        )}

        {calculators && (
          <div
            onClick={handleCalculators}
            className="text-black bg-gray-200  flex w-[30%] md:w-1/3 items-center justify-center cursor-pointer lg:rounded-tl-lg"
          >
           Apps <FaChevronUp className="ml-[2px] mt-[2px]" />
          </div>
        )}

        <Link
          className="text-black bg-white  flex w-[40%] text-center md:w-1/3 items-center justify-center cursor-pointer"
          onClick={shutCalculator}
          to="/standardsdata"
        >
          Standards{" "}
        </Link>
        {!calculators && (
          <Link
            className="text-black bg-gray-200  flex w-[30%] md:w-1/3 items-center justify-center cursor-pointer lg:rounded-r-lg opacity-90"
            onClick={shutCalculator}
            to="/timecounter"
          >
            Timer{" "}
          </Link>
        )}
        {calculators && (
          <Link
            className="text-black bg-gray-200  flex w-[30%] md:w-1/3 items-center justify-center cursor-pointer lg:rounded-tr-lg opacity-90"
            onClick={shutCalculator}
            to="/timecounter"
          >
            Timer
          </Link>
        )}
      </div>

      {calculators && (
        <div className="container flex items-center mx-auto px-4 bg-gray-900 max-w-[1000px] text-white">
          <ul className="grid mt-4">
          <li className="p-2 text-gray-100">
              <Link onClick={shutCalculator} to="/bmicalculator">
                BMI Calculator
              </Link>
            </li>
          <li className="p-2 text-gray-100">
              {" "}
              <Link onClick={shutCalculator} to="/bodyfatcalculator">
                Body Fat Calculator
              </Link>
            </li>
            
          <li className="p-2 text-gray-100">
              <Link onClick={shutCalculator} to="/onerepcalculator">
                One Rep Calculator 💯
              </Link>
            </li>
            <li className="p-2 text-gray-100">
              <Link onClick={shutCalculator} to="/strengthcalculator">
                Strength Calculator
              </Link>
            </li>
          

          

            <li className="p-2 text-gray-100">
              <Link onClick={shutCalculator} to="/pushupscalculator">
                Push Up Calculator
              </Link>
            </li>

      
          </ul>
        </div>
      )}
    </div>
  );
};

export default  Navbar;
