import React from "react";
import {
  IoStatsChartOutline,
  IoStatsChart,
  IoCalculatorOutline,
  IoCalculator,
  IoTimerOutline,
  IoTimer,
} from "react-icons/io5";

import { IoSpeedometerOutline } from "react-icons/io5";
import { IoSpeedometerSharp } from "react-icons/io5";

import { Link, useLocation } from "react-router-dom";

const BottomNavBar = ({setCalculators}) => {
  const { pathname } = useLocation();




  return (
    <div className=" md:invisible w-full h-[60px] bg-white text-white fixed left-0 bottom-0  grid grid-cols-4 content-center items-center border-t-[1px]">
      <Link onClick={() => setCalculators(false)} to="/strengthcalculator">
        <div className="flex flex-col justify-center items-center text-gray-700 text-[20px]">
          {pathname === "/strengthcalculator" ? (
            <>
              <IoCalculator className="text-blue-700" />{" "}
              <span className="text-[12px] pt-1 text-blue-700">Strength</span>
            </>
          ) : (
            <>
              <IoCalculatorOutline />{" "}
              <span className="text-[12px] pt-1">Strength</span>
            </>
          )}
        </div>
      </Link>

      <Link onClick={() => setCalculators(false)} className="bg-transparent" to="/onerepcalculator">
        <div className="flex flex-col justify-center items-center text-gray-700 text-[20px]">
          {pathname === "/onerepcalculator" ? (
            <>
              <IoSpeedometerSharp className="text-blue-700" />{" "}
              <span className="text-[12px] pt-1 text-blue-700">1RM</span>
            </>
          ) : (
            <>
              <IoSpeedometerOutline />{" "}
              <span className="text-[12px] pt-1">1RM</span>
            </>
          )}
        </div>
      </Link>

      <Link onClick={() => setCalculators(false)} to="/standardsdata">
        <div className="flex flex-col justify-center items-center text-gray-700 text-[20px]">
          {pathname === "/standardsdata" ? (
            <>
              <IoStatsChart className="text-blue-700" />{" "}
              <span className="text-[12px] pt-1 text-blue-700">Standards</span>
            </>
          ) : (
            <>
              <IoStatsChartOutline />{" "}
              <span className="text-[12px] pt-1">Standards</span>
            </>
          )}
        </div>
      </Link>
      <Link  onClick={() => setCalculators(false)} className="bg-transparent" to="/timecounter">
        <div className="flex flex-col justify-center items-center text-gray-700 text-[20px]">
          {pathname === "/timecounter" ? (
            <>
              <IoTimer className="text-blue-700" /> <span className="text-[12px] pt-1 text-blue-700">Timer</span>
            </>
          ) : (
            <>
              <IoTimerOutline /> <span className="text-[12px] pt-1">Timer</span>
            </>
          )}
        </div>
      </Link>
    </div>
  );
};

export default BottomNavBar;
