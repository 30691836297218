import React from "react";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <div className="text-white text-center pt-5 pb-5">
     Orkuta 2022 | <Link to="/privacy"> Privacy Policy </Link>
    </div>
  );
};

export default Footer;
