import React from "react";
import StrengthCalc from "../components/StrengthCalc";

const StrengthCalculator = () => {
  return (
    <div className="max-w-[1000px] m-auto shadow-xl rounded-xl md:pb-10 pt-5 md:pt-10 md:mt-10 md:bg-gray-900 border-2 border-gray-700">

    <div className="page-container max-w-[900px] rounded-lg">
          <StrengthCalc />
      </div>
      </div>
  );
};

export default StrengthCalculator;
