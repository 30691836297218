export const exerciseNamesSearch = [
  {
    label: "Barbell",
    options: [
      {
        value: "bench press",
        label: "Bench Press",
      },
      {
        value: "squat",
        label: "Squat",
      },
      {
        value: "deadlift",
        label: "Deadlift",
      },
      {
        value: "shoulder press",
        label: "Shoulder Press",
      },
      {
        value: "barbell curl",
        label: "Barbell Curl",
      },
      {
        value: "bent over row",
        label: "Bent Over Row",
      },
      {
        value: "front squat",
        label: "Front Squat",
      },
      {
        value: "incline bench press",
        label: "Incline Bench Press",
      },
      {
        value: "power clean",
        label: "Power Clean",
      },
      {
        value: "hex bar deadlift",
        label: "Hex Bar Deadlift",
      },
      {
        value: "military press",
        label: "Military Press",
      },
      {
        value: "sumo deadlift",
        label: "Sumo Deadlift",
      },
      {
        value: "hip thrust",
        label: "Hip Trust",
      },
      {
        value: "romanian deadlift",
        label: " Romanian Deadlift",
      },
      {
        value: "clean and jerk",
        label: "Clean and Jerk",
      },
      {
        value: "snatch",
        label: "Snatch",
      },
      {
        value: "clean",
        label: "Clean",
      },
      {
        value: "push press",
        label: "Push Press",
      },
      {
        value: "barbell shrug",
        label: "Barbell Shrug",
      },
      {
        value: "decline bench press",
        label: "Decline Bench Press",
      },
      {
        value: "close grip bench press",
        label: "Close Grip Bench Press",
      },
      {
        value: "clean and press",
        label: "Clean and Press",
      },
      {
        value: "t bar row",
        label: "T Bar Row",
      },
      {
        value: "ez bar curl",
        label: "EZ Bar Curl",
      },
      {
        value: "lying tricep extension",
        label: "Lying Tricep Extension",
      },
      {
        value: "seated shoulder press",
        label: "Seated Shoulder Press",
      },
      {
        value: "rack pull",
        label: "Rack Pull",
      },
      {
        value: "preacher curl",
        label: "Preacher Curl",
      },
      {
        value: "pendlay row",
        label: "Pendlay Row",
      },
      {
        value: "upright row",
        label: "Upright Row",
      },
      {
        value: "tricep extension",
        label: "Tricep Extension",
      },
      {
        value: "box squat",
        label: "Box Squat",
      },
      {
        value: "bulgarian split squat",
        label: "Bulgarian Split Squat",
      },
      {
        value: "floor press",
        label: "Floor Press",
      },
      {
        value: "good morning",
        label: "Good Morning",
      },
      {
        value: "hang clean",
        label: "Hang Clean",
      },
      {
        value: "stiff leg deadlift",
        label: "Stiff Leg Deadlift",
      },
      {
        value: "barbell lunge",
        label: "Barbell Lunge",
      },
      {
        value: "smith machine bench press",
        label: "Smith Machine Bench Press",
      },
      {
        value: "zercher squat",
        label: "Zercher Squat",
      },
      {
        value: "overhead squat",
        label: "Overhead Squat",
      },
      {
        value: "smith machine squat",
        label: "Smith Machine Squat",
      },
      {
        value: "thruster",
        label: "Thruster",
      },
      {
        value: "wrist curl",
        label: "Wrist Curl",
      },
      {
        value: "push jerk",
        label: "Push Jerk",
      },
      {
        value: "power snatch",
        label: "Power Snatch",
      },
      {
        value: "split squat",
        label: "Split Squat",
      },
      {
        value: "reverse grip bench press",
        label: "Reverse Grip Bench Press",
      },
      {
        value: "reverse wrist curl",
        label: "Reverse Wrist Curl",
      },
      {
        value: "barbell calf raise",
        label: "Barbell Calf Raise",
      },
      {
        value: "landmine squat",
        label: "Landmine Squat",
      },
      {
        value: "reverse barbell curl",
        label: "Reverse Barbell Curl",
      },
      {
        value: "barbell glute bridge",
        label: "Barbell Glute Bridge",
      },
      {
        value: "barbell reverse lunge",
        label: "Barbell Reverse Lunge",
      },
      {
        value: "deficit deadlift",
        label: "Deficit Deadlift",
      },
      {
        value: "paused bench press",
        label: "Paused Bench Press",
      },
      {
        value: "strict curl",
        label: "Strict Curl",
      },
      {
        value: "neck curl",
        label: "Neck Curl",
      },
      {
        value: "bench pin press",
        label: "Bench Pin Press",
      },
      {
        value: "barbell hack squat",
        label: "Barbell Hack Squat",
      },
      {
        value: "safety bar squat",
        label: "Safety Bar Squat",
      },
      {
        value: "barbell front raise",
        label: "Barbell Front Raise",
      },
      {
        value: "behind the neck press",
        label: "Behind The Neck Press",
      },
      {
        value: "bench pull",
        label: "Bench Pull",
      },
      {
        value: "pause squat",
        label: "Pause Squat",
      },
      {
        value: "clean high pull",
        label: "Clean High Pull",
      },
      {
        value: "single leg romanian deadlift",
        label: "Single Leg Romanian Deadlift",
      },
      {
        value: "split jerk",
        label: "Split Jerk",
      },
      {
        value: "sumo squat",
        label: "Sumo Squat",
      },
      {
        value: "snatch deadlift",
        label: "Snatch Deadlift",
      },
      {
        value: "hang power clean",
        label: "Hang Power Clean",
      },
      {
        value: "yates row",
        label: "Yates Row",
      },
      {
        value: "jefferson deadlift",
        label: "Jefferson Deadlift",
      },
      {
        value: "barbell pullover",
        label: "Barbell Pullover",
      },
      {
        value: "pause deadlift",
        label: "Pause Deadlift",
      },
      {
        value: "single leg deadlift",
        label: "Single Leg Deadlift",
      },
      {
        value: "landmine press",
        label: "Landmine Press",
      },
      {
        value: "muscle snatch",
        label: "Muscle Snatch",
      },
      {
        value: "wide grip bench press",
        label: "Wide Grip Bench Press",
      },
      {
        value: "z press",
        label: "Z Press",
      },
      {
        value: "neck extension",
        label: "Neck Extension",
      },
      {
        value: "reverse curl",
        label: "Reverse Curl",
      },
      {
        value: "shoulder pin press",
        label: "Shoulder Pin Press",
      },
      {
        value: "viking press",
        label: "Viking Press",
      },
      {
        value: "snatch pull",
        label: "Snatch Pull",
      },
      {
        value: "spider curl",
        label: "Spider Curl",
      },
      {
        value: "jm press",
        label: "JM Press",
      },
      {
        value: "pin squat",
        label: "Pin Squat",
      },
      {
        value: "wall ball",
        label: "Wall Ball",
      },
      {
        value: "cheat curl",
        label: "Cheat Curl",
      },
      {
        value: "walking lunge",
        label: "Walking Lunge",
      },
      {
        value: "close grip incline bench press",
        label: "Close Grip Incline Bench Press",
      },
      {
        value: "smith machine shrug",
        label: "Smith Machine Shrug",
      },
      {
        value: "single arm landmine press",
        label: "Single Arm Landmine Press",
      },
      {
        value: "hex bar shrug",
        label: "Hex Bar Shrug",
      },
      {
        value: "barbell power shrug",
        label: "Barbell Power Shrug",
      },
      {
        value: "zercher deadlift",
        label: "Zercher Deadlift",
      },
      {
        value: "paused squat",
        label: "Paused Squat",
      },
      {
        value: "half squat",
        label: "Half Squat",
      },
      {
        value: "hang snatch",
        label: "Hang Snatch",
      },
      {
        value: "jefferson squat",
        label: "Jefferson Squat",
      },
      {
        value: "bent arm barbell pullover",
        label: "Bent Arm Barbell Pullover",
      },
      {
        value: "meadows row",
        label: "Meadows Row",
      },
      {
        value: "spoto press",
        label: "Spoto Press",
      },
      {
        value: "behind the back barbell shrug",
        label: "Behind The Back Barbell Shrug",
      },
      {
        value: "behind the back deadlift",
        label: "Behind The Back Deadlift",
      },
    ],
  },
  {
    label: "Dumbbell",
    options: [
      {
        value: "dumbbell bench press",
        label: "Dumbbell Bench Press",
      },
      {
        value: "dumbbell curl",
        label: "Dumbbell Curl",
      },
      {
        value: "dumbbell shoulder press",
        label: "Dumbbell Shoulder Press",
      },
      {
        value: "incline dumbbell bench press",
        label: "Incline Dumbbell Bench Press",
      },
      {
        value: "dumbbell row",
        label: "Dumbbell Row",
      },
      {
        value: "dumbbell lateral raise",
        label: "Dumbbell Lateral Raise",
      },
      {
        value: "hammer curl",
        label: "Hammer Curl",
      },
      {
        value: "dumbbell fly",
        label: "Dumbbell Fly",
      },
      {
        value: "dumbbell lunge",
        label: "Dumbbell Lunge",
      },
      {
        value: "dumbbell shrug",
        label: "Dumbbell Shrug",
      },
      {
        value: "goblet squat",
        label: "Goblet Squat",
      },
      {
        value: "dumbbell tricep extension",
        label: "Dumbbell Tricep Extension",
      },
      {
        value: "dumbbell bulgarian split squat",
        label: "Dumbbell Bulgarian Split Squat",
      },
      {
        value: "dumbbell front raise",
        label: "Dumbbell Front Raise",
      },
      {
        value: "dumbbell concentration curl",
        label: "Dumbbell Concentration Curl",
      },
      {
        value: "arnold press",
        label: "Arnold Press",
      },
      {
        value: "incline dumbbell fly",
        label: "Incline Dumbbell Fly",
      },
      {
        value: "dumbbell romanian deadlift",
        label: "Dumbbell Romanian Deadlift",
      },
      {
        value: "dumbbell pullover",
        label: "Dumbbell Pullover",
      },
      {
        value: "lying dumbbell tricep extension",
        label: "Lying Dumbbell Tricep Extension",
      },
      {
        value: "incline dumbbell curl",
        label: "Incline Dumbbell Curl",
      },
      {
        value: "dumbbell floor press",
        label: "Dumbbell Floor Press",
      },
      {
        value: "dumbbell reverse fly",
        label: "Dumbbell Revers Fly",
      },
      {
        value: "dumbbell tricep kickback",
        label: "Dumbbell Tricep Kickback",
      },
      {
        value: "chest supported dumbbell row",
        label: "Chest Supported Dumbbell Row",
      },
      {
        value: "decline dumbbell bench press",
        label: "Decline Dumbbell Bench Press",
      },
      {
        value: "dumbbell squat",
        label: "Dumbbell Squat",
      },
      {
        value: "dumbbell deadlift",
        label: "Dumbbell Deadlift",
      },
      {
        value: "dumbbell upright row",
        label: "Dumbbell Upright Row",
      },
      {
        value: "dumbbell wrist curl",
        label: "Dumbbell Wrist Curl",
      },
      {
        value: "close grip dumbbell bench press",
        label: "Close Grip Dumbbell Bench Press",
      },
      {
        value: "dumbbell preacher curl",
        label: "Dumbbell Preacher Curl",
      },
      {
        value: "incline hammer curl",
        label: "Incline Hammer Curl",
      },
      {
        value: "dumbbell side bend",
        label: "Dumbbell Side Bend",
      },
      {
        value: "seated dumbbell shoulder press",
        label: "Seated Dumbbell Shoulder Press",
      },
      {
        value: "dumbbell snatch",
        label: "Dumbbell Snatch",
      },
      {
        value: "dumbbell bench pull",
        label: "Dumbbell Bench Pull",
      },
      {
        value: "renegade row",
        label: "Renegade Row",
      },
      {
        value: "zottman curl",
        label: "Zottman Curl",
      },
      {
        value: "tate press",
        label: "Tate Press",
      },
      {
        value: "dumbbell z press",
        label: "Dumbbell Z Press",
      },
      {
        value: "bent over dumbbell row",
        label: "Bent Over Dumbbell Row",
      },
      {
        value: "seated dumbbell curl",
        label: "Seated Dumbbell Curl",
      },
      {
        value: "dumbbell front squat",
        label: "Dumbbell Front Squat",
      },
      {
        value: "dumbbell reverse wrist curl",
        label: "Dumbbell Reverse Wrist Curl",
      },
      {
        value: "dumbbell push press",
        label: "Dumbbell Push Press",
      },
      {
        value: "dumbbell external rotation",
        label: "Dumbbell External Rotation",
      },
      {
        value: "dumbbell split squat",
        label: "Dumbbell Split Squat",
      },
      {
        value: "dumbbell incline y raise",
        label: "Dumbbell Incline Y Raise",
      },
      {
        value: "dumbbell reverse curl",
        label: "Dumbbell Reverse Curl",
      },
      {
        value: "single leg dumbbell deadlift",
        label: "Single Leg Dumbbell Deadlift",
      },
      {
        value: "decline dumbbell fly",
        label: "Decline Dumbbell Fly",
      },
      {
        value: "dumbbell high pull",
        label: "Dumbbell High Pull",
      },
      {
        value: "dumbbell face pull",
        label: "Dumbbell Face Pull",
      },
      {
        value: "dumbbell clean and press",
        label: "Dumbbell Clean and Press",
      },
      {
        value: "dumbbell hang clean",
        label: "Dumbbell Hang Clean",
      },
      {
        value: "dumbbell thruster",
        label: "Dumbbell Thruster",
      },
      {
        value: "dumbbell walking calf raise",
        label: "Dumbbell Walking Calf Raise",
      },
    ],
  },
  {
    label: "Machine",
    options: [
      {
        value: "sled leg press",
        label: "Sled Leg Press",
      },
      {
        value: "horizontal leg press",
        label: "Horizontal leg press",
      },
      {
        value: "leg extension",
        label: "Leg Extension",
      },
      {
        value: "chest press",
        label: "Chest Press",
      },
      {
        value: "seated leg curl",
        label: "Seated Leg Curl",
      },
      {
        value: "machine calf raise",
        label: "Machine Calf Raise",
      },
      {
        value: "lying leg curl",
        label: "Lying Leg Curl",
      },
      {
        value: "vertical leg press",
        label: "Vertical Leg Press",
      },
      {
        value: "machine shoulder press",
        label: "Machine Shoulder Press",
      },
      {
        value: "machine shoulder press",
        label: "Macine Shoulder Press",
      },
      {
        value: "machine chest fly",
        label: "Machine Chest Fly",
      },
      {
        value: "hack squat",
        label: "Hack Squat",
      },
      {
        value: "seated calf raise",
        label: "Seated Calf Raise",
      },
      {
        value: "hip abduction",
        label: "Hip Abduction",
      },
      {
        value: "seated dip machine",
        label: "Seated Dip Machine",
      },
      {
        value: "machine seated crunch",
        label: "Machine Seated Crunch",
      },
      {
        value: "standing leg curl",
        label: "Standing Leg Curl",
      },
      {
        value: "machine tricep extension",
        label: "Machine Tricep Extension",
      },
      {
        value: "belt squat",
        label: "Belt Squat",
      },
      {
        value: "machine bicep curl",
        label: "Machine Bicep Curl",
      },
      {
        value: "machine reverse fly",
        label: "Machine Reverse Fly",
      },
      {
        value: "single leg press",
        label: "Single Leg Press",
      },
      {
        value: "sled press calf raise",
        label: "Sled Press Calf Raise",
      },
      {
        value: "machine back extension",
        label: "Machine back Extension",
      },
      {
        value: "machine lateral raise",
        label: "Machine Lateral Raise",
      },
      {
        value: "single leg seated calf raise",
        label: "Single Leg Seated Calf Raise",
      },
      {
        value: "machine shrug",
        label: "Machine Shrug",
      },
    ],
  },
  {
    label: "Cable",
    options: [
      {
        value: "lat pulldown",
        label: "Lat Pulldown",
      },
      {
        value: "tricep pushdown",
        label: "Tricep Pushdown",
      },
      {
        value: "seated cable row",
        label: "Seated Cable Row",
      },
      {
        value: "tricep rope pushdown",
        label: "Tricep Rope Pushdown",
      },
      {
        value: "cable bicep curl",
        label: "Cable Bicep Curl",
      },
      {
        value: "cable lateral raise",
        label: "Cable Lateral Raise",
      },
      {
        value: "cable fly",
        label: "Cable Fly",
      },
      {
        value: "cable crunch",
        label: "Cable Crunch",
      },
      {
        value: "face pull",
        label: "Face Pull",
      },
      {
        value: "cable pull through",
        label: "Cable Pull Trough",
      },
      {
        value: "one arm cable bicep curl",
        label: "One Arm Cable Bicep Curl",
      },
      {
        value: "close grip lat pulldown",
        label: "Close Grip Lat Pulldown",
      },
      {
        value: "cable crossover",
        label: "Cable Crossover",
      },
      {
        value: "cable overhead tricep extension",
        label: "Cable Overhead Tricep Extension",
      },
      {
        value: "cable reverse fly",
        label: "Cable Reverse Fly",
      },
      {
        value: "reverse grip lat pulldown",
        label: "Reverse Grip Lat Pulldown",
      },
      {
        value: "straight arm pulldown",
        label: "Straight Arm Pulldown",
      },
      {
        value: "cable woodchoppers",
        label: "Cable Coodchoppers",
      },
      {
        value: "reverse grip tricep pushdown",
        label: "Reverse Grip Tricep Pushdown",
      },
      {
        value: "cable kickback",
        label: "Cable Kickback",
      },
      {
        value: "cable upright row",
        label: "Cable Upright Row",
      },
      {
        value: "one arm lat pulldown",
        label: "One Arm Lat Pulldown",
      },
      {
        value: "one arm seated cable row",
        label: "One Arm Seated Cable Row",
      },
      {
        value: "cable hammer curl",
        label: "Cable Hammer Curl",
      },
      {
        value: "cable leg extension",
        label: "Cable Leg Extension",
      },
      {
        value: "one arm pulldown",
        label: "One Arm Pulldown",
      },
      {
        value: "cable external rotation",
        label: "Cable External Rotation",
      },
      {
        value: "cable shrug",
        label: "Cable Shrug",
      },
      {
        value: "overhead cable curl",
        label: "Overhead Cable Curl",
      },
      {
        value: "standing cable crunch",
        label: "Standing Cable Crunch",
      },
      {
        value: "lying cable curl",
        label: "Lying Cable Curl",
      },
      {
        value: "incline cable curl",
        label: "Incline Cable Curl",
      },
      {
        value: "high pulley crunch",
        label: "High Pully Crunch",
      },
    ],
  },
];
