
const KiloTable = ({ exercise }) => {
  const { beginner, novice, intermediate, advance, elite } = exercise;
  return (
    <div className="container flex mx-auto max-w-[1000px] bg-white text-black p-6">
      <table className="table-fixed w-full shadow-md rounded border-slate-500">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-2 text-left font-bold">(Kg)</th>
            <th className="p-2 text-left font-bold" >Beg.</th>
            <th className="p-2 text-left font-bold">Nov.</th>
            <th className="p-2 text-left font-bold">Int.</th>
            <th className="p-2 text-left font-bold">Adv.</th>
            <th className="p-2 text-left font-bold">Elite</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-400">
        <tr className="even:bg-[#FAFAFA] odd:bg-white">
        
        <td className="p-2">{40}</td>
            <td className="p-2">{Math.round(40 * beginner)}</td>
            <td className="p-2">{Math.round(40 * novice)}</td>
            <td className="p-2">{Math.round(40 * intermediate)}</td>
            <td className="p-2">{Math.round(40 * advance)}</td>
            <td className="p-2">{Math.round(40 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
        
          <td className="p-2">{45}</td>
              <td className="p-2">{Math.round(45 * beginner)}</td>
              <td className="p-2">{Math.round(45 * novice)}</td>
              <td className="p-2">{Math.round(45 * intermediate)}</td>
              <td className="p-2">{Math.round(45 * advance)}</td>
              <td className="p-2">{Math.round(45 * elite)}</td>
            </tr>
            <tr className="even:bg-[#FAFAFA] odd:bg-white">
        
            <td className="p-2">{50}</td>
                <td className="p-2">{Math.round(50 * beginner)}</td>
                <td className="p-2">{Math.round(50 * novice)}</td>
                <td className="p-2">{Math.round(50 * intermediate)}</td>
                <td className="p-2">{Math.round(50 * advance)}</td>
                <td className="p-2">{Math.round(50 * elite)}</td>
              </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{55}</td>
            <td className="p-2">{Math.round(55 * beginner)}</td>
            <td className="p-2">{Math.round(55 * novice)}</td>
            <td className="p-2">{Math.round(55 * intermediate)}</td>
            <td className="p-2">{Math.round(55 * advance)}</td>
            <td className="p-2">{Math.round(55 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{60}</td>
            <td className="p-2">{Math.round(60 * beginner)}</td>
            <td className="p-2">{Math.round(60 * novice)}</td>
            <td className="p-2">{Math.round(60 * intermediate)}</td>
            <td className="p-2">{Math.round(60 * advance)}</td>
            <td className="p-2">{Math.round(60 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{65}</td>
            <td className="p-2">{Math.round(65 * beginner)}</td>
            <td className="p-2">{Math.round(65 * novice)}</td>
            <td className="p-2">{Math.round(65 * intermediate)}</td>
            <td className="p-2">{Math.round(65 * advance)}</td>
            <td className="p-2">{Math.round(65 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{70}</td>
            <td className="p-2">{Math.round(70 * beginner)}</td>
            <td className="p-2">{Math.round(70 * novice)}</td>
            <td className="p-2">{Math.round(70 * intermediate)}</td>
            <td className="p-2">{Math.round(70 * advance)}</td>
            <td className="p-2">{Math.round(70 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{75}</td>
            <td className="p-2">{Math.round(75 * beginner)}</td>
            <td className="p-2">{Math.round(75 * novice)}</td>
            <td className="p-2">{Math.round(75 * intermediate)}</td>
            <td className="p-2">{Math.round(75 * advance)}</td>
            <td className="p-2">{Math.round(75 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{80}</td>
            <td className="p-2">{Math.round(80 * beginner)}</td>
            <td className="p-2">{Math.round(80 * novice)}</td>
            <td className="p-2">{Math.round(80 * intermediate)}</td>
            <td className="p-2">{Math.round(80 * advance)}</td>
            <td className="p-2">{Math.round(80 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{85}</td>
            <td className="p-2">{Math.round(85 * beginner)}</td>
            <td className="p-2">{Math.round(85 * novice)}</td>
            <td className="p-2">{Math.round(85 * intermediate)}</td>
            <td className="p-2">{Math.round(85 * advance)}</td>
            <td className="p-2">{Math.round(85 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{90}</td>
            <td className="p-2">{Math.round(90 * beginner)}</td>
            <td className="p-2">{Math.round(90 * novice)}</td>
            <td className="p-2">{Math.round(90 * intermediate)}</td>
            <td className="p-2">{Math.round(90 * advance)}</td>
            <td className="p-2">{Math.round(90 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{95}</td>
            <td className="p-2">{Math.round(95 * beginner)}</td>
            <td className="p-2">{Math.round(95 * novice)}</td>
            <td className="p-2">{Math.round(95 * intermediate)}</td>
            <td className="p-2">{Math.round(95 * advance)}</td>
            <td className="p-2">{Math.round(95 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{100}</td>
            <td className="p-2">{Math.round(100 * beginner)}</td>
            <td className="p-2">{Math.round(100 * novice)}</td>
            <td className="p-2">{Math.round(100 * intermediate)}</td>
            <td className="p-2">{Math.round(100 * advance)}</td>
            <td className="p-2">{Math.round(100 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{105}</td>
            <td className="p-2">{Math.round(105 * beginner)}</td>
            <td className="p-2">{Math.round(105 * novice)}</td>
            <td className="p-2">{Math.round(105 * intermediate)}</td>
            <td className="p-2">{Math.round(105 * advance)}</td>
            <td className="p-2">{Math.round(105 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{110}</td>
            <td className="p-2">{Math.round(110 * beginner)}</td>
            <td className="p-2">{Math.round(110 * novice)}</td>
            <td className="p-2">{Math.round(110 * intermediate)}</td>
            <td className="p-2">{Math.round(110 * advance)}</td>
            <td className="p-2">{Math.round(110 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{115}</td>
            <td className="p-2">{Math.round(115 * beginner)}</td>
            <td className="p-2">{Math.round(115 * novice)}</td>
            <td className="p-2">{Math.round(115 * intermediate)}</td>
            <td className="p-2">{Math.round(115 * advance)}</td>
            <td className="p-2">{Math.round(115 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{120}</td>
            <td className="p-2">{Math.round(120 * beginner)}</td>
            <td className="p-2">{Math.round(120 * novice)}</td>
            <td className="p-2">{Math.round(120 * intermediate)}</td>
            <td className="p-2">{Math.round(120 * advance)}</td>
            <td className="p-2">{Math.round(120 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{125}</td>
            <td className="p-2">{Math.round(125 * beginner)}</td>
            <td className="p-2">{Math.round(125 * novice)}</td>
            <td className="p-2">{Math.round(125 * intermediate)}</td>
            <td className="p-2">{Math.round(125 * advance)}</td>
            <td className="p-2">{Math.round(125 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{130}</td>
            <td className="p-2">{Math.round(130 * beginner)}</td>
            <td className="p-2">{Math.round(130 * novice)}</td>
            <td className="p-2">{Math.round(130 * intermediate)}</td>
            <td className="p-2">{Math.round(130 * advance)}</td>
            <td className="p-2">{Math.round(130 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{135}</td>
            <td className="p-2">{Math.round(135 * beginner)}</td>
            <td className="p-2">{Math.round(135 * novice)}</td>
            <td className="p-2">{Math.round(135 * intermediate)}</td>
            <td className="p-2">{Math.round(135 * advance)}</td>
            <td className="p-2">{Math.round(135 * elite)}</td>
          </tr>
          <tr className="even:bg-[#FAFAFA] odd:bg-white">
            <td className="p-2">{140}</td>
            <td className="p-2">{Math.round(140 * beginner)}</td>
            <td className="p-2">{Math.round(140 * novice)}</td>
            <td className="p-2">{Math.round(140 * intermediate)}</td>
            <td className="p-2">{Math.round(140 * advance)}</td>
            <td className="p-2">{Math.round(140 * elite)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default KiloTable;
