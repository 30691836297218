import React from "react";
import RestInfo from "../components/RestInfo";
import Timer from "../components/Timer";

const TimeCounter = () => {
  return (
    <div className="max-w-[1000px] m-auto shadow-xl rounded-xl md:pb-10 pt-5 md:pt-10 md:mt-10 md:bg-gray-900 border-2 border-gray-700">

    <div className="page-container max-w-[900px] rounded-lg">
      <div className="grid pb-4 grid-cols-1  gap-6">
        <div className="text-center bg-gray-100 p-6 rounded shadow">
          <Timer />
        </div>
        <div className="bg-gray-100 p-6 rounded shadow">
          <RestInfo />
        </div>
      </div>
    </div>
    </div>

  );
};

export default TimeCounter;
