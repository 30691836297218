import React, { useEffect, useRef, useState } from "react";
import useSound from "use-sound";
import mySound from "../assests/alarm.mp3";

//Adds zero in front of minutes and seconds when the time is a single digit character.
function padTime(time) {
  return time.toString().padStart(2, "0");
}

const Timer = () => {
  // Timer States
  const [timeLeft, setTimeLeft] = useState(30);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [play] = useSound(mySound);

  // Timer variables
  const minutes = padTime(Math.floor(timeLeft / 60));
  const seconds = padTime(timeLeft - minutes * 60);
  const intervalRef = useRef(null);

  // Starts countdown timer
  function startTimer() {
    setIsRunning(true);

    intervalRef.current = setInterval(() => {
      setTimeLeft((timeLeft) => {
        if (timeLeft >= 1) {
          return timeLeft - 1;
        } else {
          return 0;
        }
      });
    }, 1000);
  }

  useEffect(() => {
    if (timeLeft === 0) {
      play();
    }
  }, [timeLeft, play]);

  // Stops Timer
  function stopTimer() {
    clearInterval(intervalRef.current);

    setIsRunning(false);
  }

  return (
    <div>

      <h1 className="text-xl font-bold mt-4">Count Down Timer</h1>

      <div className="mt-5 text-[4rem] rounded-xl shadow bg-white">
        <span>{minutes}</span>
        <span>:</span>
        <span>{seconds}</span>
      </div>

      <div className="flex justify-center mt-10">
        {!isRunning && (
          <button
            className="shadow bg-blue-700 hover:bg-blue-500  focus:shadow-outline focus:outline-none text-white  py-2 px-6  rounded text-xl"
            onClick={startTimer}
          >
            START
          </button>
        )}

        <br />
        {isRunning && timeLeft > 0 && (
          <button
            className="  border-[1px] bg-red-700 hover:red-500 text-white px-7 py-2 mr-1 rounded text-xl"
            onClick={stopTimer}
          >
            STOP
          </button>
        )}

        {timeLeft === 0 && (
          <p className="font-bold text-lg text-red-700">Times Up!</p>
        )}
      </div>
      <div className="mt-10 grid gap-2">
        <button className="btn-timer mr-1" onClick={() => setTimeLeft(15)}>
          15 sec
        </button>

        <button className="btn-timer mr-1" onClick={() => setTimeLeft(30)}>
          30 sec
        </button>

        <button className="btn-timer mr-1" onClick={() => setTimeLeft(45)}>
          45 sec
        </button>

        <button className="btn-timer mr-1" onClick={() => setTimeLeft(60)}>
          60 sec
        </button>
        <button className="btn-timer mr-1" onClick={() => setTimeLeft(90)}>
          90 sec
        </button>
        <button className="btn-timer mr-1" onClick={() => setTimeLeft(120)}>
          2 mins
        </button>
        <button className="btn-timer mr-1 " onClick={() => setTimeLeft(180)}>
          3 mins
        </button>
        <button className="btn-timer mr-1 " onClick={() => setTimeLeft(240)}>
          4 mins
        </button>
        <button
          className="btn-timer mr-1 mb-10 "
          onClick={() => setTimeLeft(300)}
        >
          5 mins
        </button>
      </div>
    </div>
  );
};

export default Timer;
