import React from 'react'

const BodyRatio = ({exercise}) => {
  return (
    <table className="table-auto min-w-[100%] shadow-md rounded">
            <thead className="text-left">
              <tr className="text-slate-700 bg-gray-100">
                <th className="p-2">Strength Level</th>
                <th className="p-2">Body Ratio</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-400">
              <tr className="even:bg-[#FAFAFA] odd:bg-white">
                <td className="p-2">Beginner</td>

                <td className="p-2">
                  {exercise.beginner.toFixed(2).toLocaleString()}x
                </td>
              </tr>
              <tr className="even:bg-[#FAFAFA] odd:bg-white">
                <td className="p-2">Novice</td>
                <td className="p-2">
                  {exercise.novice.toFixed(2).toLocaleString()}x
                </td>
              </tr>
              <tr className="even:bg-[#FAFAFA] odd:bg-white">
                <td className="p-2">Intermediate</td>
                <td className="p-2">
                  {exercise.intermediate.toFixed(2).toLocaleString()}x
                </td>
              </tr>
              <tr className="even:bg-[#FAFAFA] odd:bg-white">
                <td className="p-2">Advance</td>
                <td className="p-2">
                  {exercise.advance.toFixed(2).toLocaleString()}x
                </td>
              </tr>
              <tr className="even:bg-[#FAFAFA] odd:bg-white">
                <td className="p-2">Elite</td>
                <td className="p-2">
                  {exercise.elite.toFixed(2).toLocaleString()}x
                </td>
              </tr>
            </tbody>
          </table>
  )
}

export default BodyRatio