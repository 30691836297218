import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StandardsData from "./routes/StandardsData";
import ErrorPage from "./routes/ErrorPage";
import StrengthCalculator from "./routes/StrengthCalculator";
import TopNavbar from "./components/TopNavbar";
import TimeCounter from "./routes/TimeCounter";
import BmiCalculator from "./routes/BmiCalculator";
import BodyFatCalculator from "./routes/BodyFatCalculator";
import OneRepMaxCalculator from "./routes/OneRepMaxCalculator";
import PushUpsCalculator from "./routes/PushUpsCalculator";
import BottomNavBar from "./components/BottomNavBar";
import shutCalculator from "./components/Navbar";
import { useState } from "react";
import Footer from "./components/Footer";
import Privacy from "./routes/Privacy";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-236112579-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [calculators, setCalculators] = useState(false);
  const [hideNavBar, setHideNavBar] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Router>
        <TopNavbar
          setCalculators={setCalculators}
          calculators={calculators}
          shutCalculator={shutCalculator}
        />
        <Routes>
          <Route path="/" element={<StandardsData />} />
          <Route path="/standardsdata" element={<StandardsData />} />
          <Route path="/bmicalculator" element={<BmiCalculator />} />
          <Route path="/bodyfatcalculator" element={<BodyFatCalculator />} />
          <Route path="/strengthcalculator" element={<StrengthCalculator />} />
          <Route path="/onerepcalculator" element={<OneRepMaxCalculator />} />
          <Route path="/pushupscalculator" element={<PushUpsCalculator />} />
          <Route path="/timecounter" element={<TimeCounter />} />
          <Route path="/privacy" element={<Privacy />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>

        {!hideNavBar && (
          <BottomNavBar
            setCalculators={setCalculators}
            calculators={calculators}
            shutCalculator={shutCalculator}
            hideNavBar={hideNavBar}
            setHideNavBar={setHideNavBar}
          />
        )}
        <Footer />
      </Router>
    </>
  );
}

export default App;
