import { useEffect, useState } from "react";
import classNames from "classnames";

const BmiCalculator = () => {
  const [weight, setWeight] = useState("");
  const [stone, setStone] = useState("");
  const [pound, setPound] = useState("");
  const [height, setHeight] = useState("");
  const [feet, setFeet] = useState("");
  const [inches, setInches] = useState("");
  const [bmi, setBmi] = useState("");
  const [weightMeasurement, setWeightMeasurement] = useState("lb");
  const [heightMeasurement, setHeightMeasurement] = useState("feet");
  const [showDetails, setShowDetails] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [show]);


  const bmiResults = classNames(
    "flex mb-4 shadow p-3  text-white rounded-lg text-center",
    {
      "bg-blue-500": bmi < 18.5,
      "bg-green-500": (bmi > 18.5) & (bmi < 25),
      "bg-orange-500": (bmi >= 25) & (bmi < 30),
      "bg-red-500": bmi >= 30,
    }
  );

  const handleHeightChange = (e) => {
    setHeightMeasurement(e.target.value);
    setHeight("");
    setFeet("");
    setInches("");
  };

  const handleWeightChange = (e) => {
    setWeightMeasurement(e.target.value);
    setWeight("");
    setPound("");
    setStone("");
  };

  const weightSwitch = () => {
    if (weightMeasurement === "lb") {
      return weight;
    } else if (weightMeasurement === "kg") {
      return weight * 2.2046226218;
    } else if (weightMeasurement === "stone") {
      return stone * 14 + Number(pound);
    }
  };

  const heightSwitch = () => {
    if (heightMeasurement === "inch") {
      return height;
    } else if (heightMeasurement === "cm") {
      return height / 2.54;
    } else if (heightMeasurement === "feet") {
      return feet * 12 + Number(inches);
    }
  };

  const bmiFunction = () => {
    let bmi =
      (weightSwitch(weight) / heightSwitch(height) / heightSwitch(height)) *
      703;
    setBmi(bmi.toFixed(1));
  };

  let calcBMI = (e) => {
    e.preventDefault();
    bmiFunction();
    setShow(true);
  };

  let reload = (e) => {
    setWeight("");
    setHeight("");
    setBmi("");
    setStone("");
    setPound("");
    setFeet("");
    setInches("");
    setShow(false)
  };

  return (
    <div className="grid  pb-4 md:pb-0  md:mx-2 grid-cols-1">
      {!show && (
        <>
          <h1 className="text-3xl mb-2 font-medium border-b-[1px] pb-4">
            BMI Calculator{" "}
          </h1>
          <p className="mb-2">
            The BMI calculator can give you an understanding of your weight and
            body mass index.
          </p>
          <hr className="my-2" />
          <div className="flex">
            <h3>Instructions:</h3>
            <span
              className="text-blue-700 font-bold underline ml-2 cursor-pointer"
              onClick={() => setShowDetails(!showDetails)}
            >
              BMI Calculator
            </span>
          </div>
          {showDetails && (
            <div className="bg-gray-100 p-4 rounded mt-3">
              <h2 className="font-bold">Steps</h2>
              <hr className="my-2" />

              <ul className="list-disc  ml-4 mt-2 text-gray-700">
                <li>Enter your body weight </li>
                <li>Enter your height </li>
              </ul>
              <hr className="my-2" />

              <div className="">
                <div>
                  <h2 className="font-bold pt-2 cursor-pointer">
                    More Details
                  </h2>
                </div>
                <hr className="my-2" />

                <div>
                  <p>
                    Adults and athletes with muscle may be classified as "obese"
                    or "overweight" even though their body fat is low.{" "}
                  </p>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={calcBMI}>
            <div className="flex flex-col order-1 md:bg- md:p-6 mt-6">
              <div className="flex">
                <div className="mr-[21px]">
                  <label className="block tracking-wide text-gray-700  pt-2 font-semibold text-sm">
                    Weight
                  </label>
                </div>
                {weightMeasurement === "stone" && (
                  <div className="grow">
                    <input
                      className="rounded-none rounded-l-lg"
                      value={stone}
                      type="number"
                      required
                      placeholder="stone"
                      onChange={(e) => setStone(e.target.value)}
                    />
                  </div>
                )}
                {weightMeasurement === "stone" && (
                  <div className="grow">
                    <input
                      className="rounded-none"
                      value={pound}
                      type="number"
                      required
                      placeholder="pound"
                      onChange={(e) => setPound(e.target.value)}
                    />
                  </div>
                )}

                {weightMeasurement === "kg" && (
                  <div className="grow">
                    <input
                      className="rounded-none rounded-l-lg"
                      value={weight}
                      type="number"
                      required
                      placeholder="kg"
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </div>
                )}

                {weightMeasurement === "lb" && (
                  <div className="grow">
                    <input
                      className="rounded-none rounded-l-lg"
                      value={weight}
                      type="number"
                      required
                      placeholder="lb"
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </div>
                )}

                <div>
                  <select
                    className="rounded-none rounded-r-lg min-w-[95px] bg-gray-100 cursor-pointer"
                    value={weightMeasurement}
                    onChange={handleWeightChange}
                  >
                    <option value="lb">lb</option>
                    <option value="kg">kg</option>
                    <option value="stone">stone</option>
                  </select>
                </div>
              </div>

              <div className="flex mt-6">
                <div className="mr-[21px]">
                  <label className="block tracking-wide text-gray-700  pt-2 font-semibold text-sm">
                    Height
                  </label>
                </div>
                {heightMeasurement === "feet" && (
                  <div className="grow">
                    <input
                      className="rounded-none rounded-l-lg"
                      value={feet}
                      type="number"
                      required
                      placeholder="foot"
                      onChange={(e) => setFeet(e.target.value)}
                    />
                  </div>
                )}
                {heightMeasurement === "feet" && (
                  <div className="grow">
                    <input
                      className="rounded-none"
                      value={inches}
                      type="number"
                      required
                      placeholder="inch"
                      onChange={(e) => setInches(e.target.value)}
                    />
                  </div>
                )}

                {heightMeasurement === "cm" && (
                  <div className="grow">
                    <input
                      className="rounded-none rounded-l-lg"
                      value={height}
                      type="number"
                      required
                      placeholder="cm"
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </div>
                )}

                {heightMeasurement === "inch" && (
                  <div className="grow">
                    <input
                      className="rounded-none rounded-l-lg"
                      value={height}
                      type="number"
                      required
                      placeholder="inch"
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </div>
                )}

                <div>
                  <select
                    className="rounded-none rounded-r-lg min-w-[95px] bg-gray-100 cursor-pointer"
                    value={heightMeasurement}
                    onChange={handleHeightChange}
                  >
                    <option value="cm">cm</option>
                    <option value="inch">inch</option>
                    <option value="feet">foot</option>
                  </select>
                </div>
              </div>
              {bmi === "" && (
                <button
                  className="flex justify-center ml-[67px]  mb-0 lg:mb-4 shadow bg-blue-700 hover:bg-blue-600  focus:shadow-outline focus:outline-none text-white  py-3 px-6  rounded text-[1.2rem] mt-7 border-b-4 border-blue-900"
                  type="submit"
                >
                  Calculate BMI
                </button>
              )}
            </div>
          </form>
        </>
      )}

      {bmi > 0 && (
        <div className="flex flex-col bg-gray-100 p-4 rounded-lg border-2">
          <div className="mt-6 mb-2 border-b-[1px]">
            <h2 className="text-lg font-bold mb-4">Results</h2>
            <div className={bmiResults}>
              {" "}
              <h3 className="font-bold ">Your BMI is: {bmi} </h3>
            </div>
          </div>

          <h2 className="font-bold text-x pt-5">BMI weight ranges</h2>

          <ul className=" border-b-1 py-4">
            <li className="pb-4">
              Less than 18.5 ={" "}
              <span className="bg-blue-500 rounded-lg p-1 px-2 text-white text-xs">
                Underweight
              </span>
            </li>
            <li className="pb-4">
              {" "}
              Between 18.5 - 24.9 ={" "}
              <span className="bg-green-500 rounded-lg p-1 px-2 text-white text-xs">
                Healthy Weight
              </span>
            </li>
            <li className="pb-4">
              Between 25 - 29.9 ={" "}
              <span className="bg-orange-500 rounded-lg p-1 px-2 text-white text-xs">
                Overweight
              </span>
            </li>
            <li className="pb-4">
              Over 30 ={" "}
              <span className="bg-red-500 rounded-lg p-1 px-2 text-white text-xs">
                Obese
              </span>
            </li>
          </ul>
          <hr></hr>
          <p className=" border-b-1 py-4">
            Your BMI can tell you if you're carrying too much weight, but it
            won't tell you if you have too much fat.
          </p>

          <hr></hr>

          <p className=" border-b-1 py-4">
            The BMI calculator cannot tell the difference between excess fat,
            muscle or bone.{" "}
          </p>
          <hr></hr>

          <p className=" border-b-1 py-4">
            Adults and athletes with muscle may be classified as "obese" or
            "overweight" even though their body fat is low.{" "}
          </p>
          <hr></hr>

          <p className=" border-b-1 py-4">
            {" "}
            Despite these limitations, BMI is an easy tool for assessing
            someone's weight.
          </p>
        </div>
      )}

      {bmi > 0 && (
        <button
        className=" flex justify-center w-full bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-3 px-6 border border-red-500 hover:border-transparent rounded text-[1.2rem] mt-10"
          onClick={reload}
        >
          Reset
        </button>
      )}
    </div>
  );
};

export default BmiCalculator;
