import React from "react";
import { BsStopwatch } from "react-icons/bs";

const RestInfo = () => {
  return (
    <div className="text-gray-800">
      <div className="flex text-[60px]">
      <BsStopwatch className="mt-4 mb-4 text-gray-800"/>

      </div>
      <h2 className="font-bold text-xl text-black border-b-[1px] py-4">
        Rest Between Sets
      </h2>
      <ul className=" border-b-1 py-4">
        <li className="pb-4 border-b-[1px] mb-4">
          <h2 className="font-bold">Strength</h2>
          To increase strength and power, the best rest period is 2-5 minutes
          between sets.
        </li>

        <li className="pb-4 border-b-[1px] mb-4">
          <h2 className="font-bold">Hypertrophy</h2>
          To increase hypertrophy (muscle growth), the best rest period is 30-90
          seconds between sets.
        </li>

        <li className="pb-4">
          <h2 className="font-bold">Endurance</h2>
          To increase muscular endurance, the best rest period is 30 seconds or
          less between sets.
        </li>
      </ul>
    </div>
  );
};

export default RestInfo;
